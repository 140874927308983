@import "../../../styles/main";

.c-MessagesCloud {
  background: white;
  border: solid 4px #fdb913;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  margin-top: 31px;
  padding: 34px 62px;
  position: relative;

  @include mobile-only {
    padding: 18px 22px;
  }

  > * {
    flex: 1;
  }

  &__messageIcon {
    align-items: center;
    display: flex;
    margin-right: 25px;
    max-width: 45px;
    width: 45px;
  }

  &__subject {
    color: $color-primary;
    font-size: $primary-font-size;
    font-weight: $ft-w-500;
    line-height: 1.25;
    margin-bottom: 6px;
  }

  &__message {
    font-size: $secondary-font-size;
    font-weight: $ft-w-500;
  }

  &__closeIcon {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
