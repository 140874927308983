@import '../../styles/main';

.c-GetVanRegPage {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    margin-top: 40px;
  }

  &__link {
    display: flex;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &__form {
    @include tablet-and-above {
      padding-top: 60px;
    }
  }

  &__fontColor {
    @include tablet-and-above {
      color: $rich-purple;
    }
  }

  &__link {
    color: $primary-blue;
    display: flex;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 5% auto 0;
    text-decoration: underline;
    width: fit-content;

    @include tablet-and-above {
      margin: 30px auto 0;
    }
  }

  &__resendEmailMessageContainer {
    margin-top: 10px;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0 0 30px;

    @include tablet-and-above {
      padding: 0;
      width: 50%;
    }
  }

  &__icon {
    height: 79px;
    width: 105px;

    @include tablet-and-above {
      height: 81px;
      width: $icon-width-desktop;
    }
  }
}
