@import '../../../styles/all';

.c-TitleWithUnderLine {
  display: block;
  width: 100%;

  &__text {
    color: $greyish-brown;
    display: block;
    font-family: $font-stack;
    font-size: 24px;
    font-style: normal;
    font-weight: 1.25;
    margin: 0;
    margin-bottom: 10px;
    position: relative;
    text-align: left;
  }

  &__customTitle {
    color: $color-primary;
    font-weight: $ft-w-500;
    font-weight: 1.25;
    margin-top: 20px;
    text-align: center;
  }

  @include tablet-and-above {
    bottom: -5px;
  }
}

@media only screen and (min-width: 800px) {
  .c-TitleWithUnderLine {
    color: $color-primary;
    width: 100%;

    &__text {
      color: $color-primary;
      font-weight: $ft-w-500;
      font-weight: 1.25;
      margin: 0;
      margin-bottom: 10px;
      position: relative;
      text-align: left;
    }
  }
}
