@import "../../styles/main";

.c-DocumentsUploadedPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__content {
    margin: auto;
    width: 100%;
  }

  &__contentContainer {
    @include mobile-only {
      margin: auto;
      width: 100%;
    }

    @include tablet-and-above {
      margin: auto;
      padding-top: $form-vertical-padding;
      text-align: left;
      width: 100%;
    }
  }

  #Icon_PDF {
    padding-left: 5px;
  }

  &__link {
    color: $color-primary;
    font-size: $secondary-font-size;
    font-weight: $ft-w-bold;
    line-height: 1.43;
    padding-left: 1px;
    text-decoration: none;
    width: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  &__linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    padding-bottom: 20px;
    width: 100%;
  }

  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }

    @include tablet-and-above {
      width: 100%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__linkIcon {
    font-size: 20px; // 1.428571429rem at font size of 14px
    padding-left: 10.9px;
  }

  &__contentMessage {
    text-align: center;
  }

  &__buttonContainer {
    margin-top: $medium-margin;

    @include tablet-and-above {
      margin: 0;
      padding-top: 20px;
    }
  }

  &__titleText {
    @include mobile-only {
      color: $tertiary-text-color;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin: 20px 0;
    }

    @include tablet-and-above {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      text-align: center;
    }
  }

  &__deviceIcon {
    height: auto;
    margin: auto;
    width: $device-icon-mobile-width;

    @include tablet-and-above {
      height: auto;
      width: $device-icon-desktop-width;
    }
  }

  &__customTitle {
    margin-top: 20px;
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;
  }

  &__noDocumentsMessage {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-top: $medium-margin;
    text-align: center;
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__heading {
    @include primaryTextFont;

    color: $color-primary;
    text-align: left;
  }

  &__text {
    @include secondaryTextFont;

    color: $tertiary-text-color;
    text-align: left;
  }

  &__date {
    font-size: $secondary-font-size;
    font-weight: $ft-w-bold;
    line-height: 1.43;
    padding-left: 1px;
    text-align: left;
    text-decoration: none;
  }

  &__redError {
    color: $error-red;
    font-size: $secondary-font-size;
    font-weight: $ft-w-bold;
    line-height: 1.43;
    padding-left: 1px;
    text-align: left;
    text-decoration: none;
  }
}

@media only screen and (min-width: $desktop-form-width) {
  .c-DocumentsUploadedPage {
    margin: auto;
    width: 100%;

    &__customTitleTextContainer {
      margin: auto;
      width: $desktop-form-width;
    }

    &__customTitleText {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin-left: 5px;
    }
  }
}
