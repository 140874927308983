@import '../../styles/main';

.c-ViewQuote {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 10% 64px;
  }

  @include desktop-and-above {
    margin: 0 auto 64px;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: $desktop-form-width;
  }

  &__card {
    @include noPaddingCardContainer;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 16px 30px;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__underwrittenText {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
  }

  &__zurichLogo {
    height: 27px;
    margin-left: 10px;
  }

  &__allianzLogo {
    height: 20px;
    margin-left: 10px;
  }

  &__avivaLogo {
    height: 40px;
    margin-left: 10px;
  }

  &__axaLogo {
    height: 40px;
    margin-left: 10px;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }

  &__quoteInfoContainer {
    margin-bottom: 15px;
    padding: 0;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 auto;
      width: 400px;
    }
  }

  &__pageTitle {
    margin-top: 40px;
    padding-bottom: 15px;
  }

  &__subTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 20px;
  }

  &__excessContainer {
    margin: 20px 10px;
  }

  &__consentSection {
    margin: 10px 0;
  }

  &__buySection {
    display: inline-flex;
    margin-top: 10px;
    width: 100%;
  }

  &__quotePriceContainer {
    margin: auto;
  }

  &__quotePrice {
    color: $color-primary;
    display: inline-flex;
  }

  &__buyButtonContainer {
    margin: auto;
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }

  &--excessValueLabel {
    margin-bottom: $small-margin;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
  }

  &--flexGroup {
    .c-ButtonGroup {
      display: flex;
      flex-direction: column;

      .c-ButtonGroup__button {
        border: solid 3px $color-primary;
        font-size: 14px;
        font-stretch: normal;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
  }

  &__eligibilityWarningTitle {
    font-size: 20px;
    font-weight: $ft-w-bold;
    text-align: center;
  }

  &__eligibilityWarningText {
    padding: 5px 0 0;
    text-align: center;
  }

  &--flexGroup {
    .c-ButtonGroup {
      display: flex;
      flex-direction: column;

      .c-ButtonGroup__button {
        margin-bottom: 15px;
      }
    }
  }

  .c-DesktopCard {
    padding-bottom: 5%;
    padding-top: 5%;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  #sectionHeading {
    color: $greyish-brown;
    font-size: 24px;
    font-weight: $ft-w-500;
    margin-top: 15px;
    padding-left: 5px;
    padding-top: 15px;
  }

  .c-AccordionCard__container {
    border-width: 0;
  }
}
