@import '../../styles/main';

.c-MessagesPage {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  @include mobile-only {
    padding: 0;
  }

  &__backButtonPadding {
    @include mobile-only {
      padding: 7.5% 7.5% 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }

  &__title {
    display: block;
    text-align: left;

    @include mobile-only {
      padding: 0 27px;
    }
  }

  &__messagesContainer {
    margin-top: 30px;
  }

  &__titleIcon {
    margin-right: 10px;
    vertical-align: middle;

    svg {
      vertical-align: middle;
    }
  }
}
