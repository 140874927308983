@import '../../styles/main';

.c-InstallPrompt {
  margin: 10px auto;
  width: 90%;

  @include tablet-and-above {
    margin: 25px auto;
    width: 40%;
  }

  &__installPromptHeading {
    color: $color-primary;
    display: none;
    font-size: $h3-size;
    font-weight: 700;
    text-align: center;
  }

  &__installPromptMessage {
    color: $tertiary-text-color;
    font-weight: 500;
  }

  &__installPromptText {
    font-size: 14px;
    line-height: 25px;
  }

  &__installPromptImage {
    color: $color-primary;
    float: left;
  }
}
