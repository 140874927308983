@import '../../styles/main.scss';

.c-HomeUpdateSpecifiedItems {
  @include standardPageLayout;

  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: $desktop-form-width;
    }

    @include desktop-and-above {
      width: $desktop-form-width;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &__form {
    @include tablet-and-above {
      padding-top: 30px;
    }

    padding-top: 30px;
  }

  &__card {
    @include noPaddingCardContainer;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 16px 30px;
  }

  &__subHeading {
    font-size: 14px;
    padding: 10px 0 30px;
  }

  &__button {
    display: flex;
    margin-bottom: 86px;
    margin-top: 40px;
  }
}
