@import '../../../../../styles/main';

.c-AdditionalDrivers {
  &__closeIcon {
    float: right;
  }

  &__dividerContainer {
    margin: $medium-margin 0;
    opacity: 0.3;
  }

  &__itemPromptContainer {
    margin-top: 15px;
  }

  &__penaltyPointsMainContainer {
    padding: 0;
  }

  &__penaltyPointsRepeatingContainer {
    padding: $form-field-padding 0;
  }
}
