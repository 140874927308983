@import '../../styles/main';

.c-ClaimContent {
  @include standardPageLayout;

  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;

  &__customBullets {
    margin: 20px 0;
    text-align: left;
  }

  ul {
    list-style: none;
    margin-top: 10px;
  }

  ul li:before {
    color: $rich-purple;
    content: "\2022";
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }

  &__phoneButtonContainer {
    margin: auto;

    @include tablet-and-above {
      margin: auto;
      width: $desktop-form-width;
    }
  }

  &__bulletHeading {
    color: $rich-purple;
    font-weight: bold;
    margin-left: 10px;
  }
}
