.c-App {
  height: 100%;
  overflow: auto;
  width: 100%;

  &__childrenContainer {
    flex: 1;
    height: calc(100% - 70px);
  }
}

.center-aligned {
  align-items: center;
  display: flex;
  justify-content: center;
}
