@import '../../../styles/all';

.c-Text {
  font-family: $font-stack;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  text-align: inherit;

  &--secondary {
    font-size: $secondary-size;
  }

  &--tertiary {
    font-size: $tertiary-size;
  }

  &--quaternary {
    font-size: $quaternary-size;
  }

  &--quinary {
    color: $color-primary;
    font-size: $quinary-size;
    font-weight: 600;
  }

  &--light {
    color: $inverted-font-color;
  }

  &--muted {
    color: $font-color-secondary;
  }

  &--bold {
    font-weight: $ft-w-bold;
  }

  &--light-font {
    font-weight: $ft-w-light;
  }

  &--inherit-size {
    font-size: inherit;
  }

  &__textAlignCenter {
    text-align: center;
  }

  &__textAlignRight {
    text-align: right;
  }

  &__textAlignLeft {
    text-align: left;
  }
}
