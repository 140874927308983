@import '../../styles/main';

.c-CarRegLookup {
  &__manualSearchButton {
    background: none;
    border: none;
    color: $color-primary;
    cursor: pointer;
    font-weight: $ft-w-bold;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &__carRegLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__errorMessage {
    color: $color-error;
  }

  &__readMore {
    color: $rich-purple;
    margin-top: -10px;
  }

  &__readMoreContent {
    color: $tertiary-text-color;
    text-decoration: none;
  }

  &__brokenLine {
    border-bottom: 1px dashed $grey-light;
  }

  &__editLabel {
    color: $color-primary;
    font-weight: $ft-w-500;
  }

  &__infoText {
    font-size: 10px;
  }
}
