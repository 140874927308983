@import '../../styles/all';

$counter-block-width: 60px;

.c-Counter {
  text-decoration: none;

  &__container {
    display: flex;
    height: $input-field-height;
  }

  &__minusButton,
  &__plusButton {
    background-color: $white;
    border: 1px solid $color-primary;
    color: $color-primary;
    font-size: 1.6em;
    font-weight: 200;
    outline: none;
    user-select: none;
    width: $counter-block-width;
  }

  &__minusButton {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  &__plusButton {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }

  &__minusButton--focused,
  &__plusButton--focused {
    background-color: $color-primary;
    color: $white;
  }

  &__content {
    align-content: center;
    background-color: $white;
    border: 1px solid $btn-tertiary-border-color;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    width: $counter-block-width;
  }

  &__count {
    margin: auto;
  }
}
