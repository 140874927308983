@import '../../styles/all';

$link-color: $color-primary;
$slider-color: $white;
$slider-border-color: $black;

.c-Slider {
  &__container {
    width: 100%;
  }

  &__slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    background: $white-gray; /* Grey background */
    border-radius: 5px;
    height: 10px; /* Specified height */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    outline: none; /* Remove outline */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    width: 100%; /* Full-width */
  }

  &__slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  &__slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    background: $slider-color; /* Green background */
    border: solid;
    border-color: $slider-border-color;
    border-radius: 50%;
    cursor: pointer; /* Cursor on hover */
    height: 25px; /* Slider handle height */
    width: 25px; /* Set a specific slider handle width */
  }

  &__slider::-moz-range-thumb {
    background: $slider-color; /* Green background */
    border: solid;
    border-color: $slider-border-color;
    border-radius: 50%;
    cursor: pointer; /* Cursor on hover */
    height: 25px; /* Slider handle height */
    width: 25px; /* Set a specific slider handle width */
  }
}
