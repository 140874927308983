@import "../../styles/main";

.c-GenericClaimPage {
  @include standardPageLayout;

  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 600px;
  }

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: $desktop-form-width;
    }

    @include desktop-and-above {
      width: $desktop-form-width;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &__cardPadding {
    padding: 16px 30px;
  }

  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }

    @include tablet-and-above {
      width: 100%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__customTitleTextContainer {
    margin: auto;
    width: 100%;
  }

  &__deviceIcon {
    height: auto;
    margin: auto;
    width: $device-icon-mobile-width;

    @include tablet-and-above {
      height: auto;
      width: $device-icon-desktop-width;
    }
  }

  &__titleSubTitleContainer {
    width: 100%;

    @include tablet-and-above {
      width: $desktop-form-width;
    }
  }

  &__titleSubTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    padding-bottom: 10px;
    text-align: left;

    @include mobile-only {
      margin-left: 20px;
    }
  }

  &__titleText {
    @include mobile-only {
      margin-left: 20px;
    }

    &--van {
      margin-bottom: 20px;
    }
  }

  &--spacing {
    margin-bottom: 30px;
  }

  &__buttonSpacing {
    margin-top: 20px;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 80%;
    }
  }

  &__customBullets {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
  }

  .c-DesktopCard {
    margin: 30px auto;
  }

  &__accordions {
    font-family: $font-stack;
    margin: auto;
    width: 100%;

    @include tablet-and-above {
      width: 600px;
    }

    ul {
      list-style: none;
      margin-top: 10px;
    }

    ul li:before {
      color: $rich-purple;
      content: "\2022";
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      margin-left: -1em;
      width: 1em;
    }
  }

  &__phoneButtonContainer {
    margin: auto;

    @include tablet-and-above {
      margin: auto;
      width: 400px;
    }
  }
}
