@import "../../styles/main";

.c-MobileHeader {
  background-image: linear-gradient($color-primary, $color-primary-dark);
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  max-height: 70px;
  min-height: 60px;
  padding: 15px 7.5%;
  text-align: center;

  &__logo {
    height: 20px;
    width: 104px;
  }

  &__barIcon {
    cursor: pointer;
    margin-top: 4px;
    width: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .c-MobileHeader {
    height: 70px;
  }
}
