@import '../../styles/all';

.c-ButtonCard {
  background-color: white;
  border: none;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  flex-grow: 1;
  height: 150px;
  margin: 0.5%;
  width: 150px;

  &__contents {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__icon {
    margin: auto;
    padding-bottom: 16px;
    width: 45px;
  }

  &__coming-soon-text {
    color: $color-primary;
    font-size: 12px;
  }

  &__main-text {
    color: $tertiary-text-color;

    &--muted {
      opacity: 0.4;
    }
  }
}

@media only screen and (min-width: 950px) {
  .c-ButtonCard {
    width: 250px;
  }
}
