@import '../../styles/all';

$active-switch-color: $color-primary;
$inactive-switch-color: $background-color-primary;
$inactive-stroke: $color-inputs;
$inactive-bg: $white;
$switch-height: 16px;
$switch-margin-left: 24px;

.c-Switch {
  background: $inactive-bg;
  border: 1px solid $inactive-stroke;
  border-radius: $switch-height;
  box-sizing: initial;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  outline: 0;
  padding: 4px;
  position: relative;
  transition: all 0.4s ease;
  user-select: none;
  width: 40px;

  &:after {
    background: $inactive-switch-color;
    border: 1px solid $inactive-stroke;
    border-radius: $switch-height;
    box-sizing: border-box;
    content: '';
    display: block;
    height: $switch-height;
    position: relative;
    right: 0;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding 0.3s
      ease,
      margin 0.3s ease;
    width: $switch-height;
  }

  &--on:after {
    background: $inactive-bg;
    border: none;
    margin-left: $switch-margin-left;
  }

  &--on {
    background: $active-switch-color;
    border: 1px solid $active-switch-color;
  }

  &__input {
    display: none;
  }

  &--switchLabelOn {
    left: 5px;
    position: absolute;
  }

  &--switchLabelOff {
    position: absolute;
    right: 5px;
  }
}
