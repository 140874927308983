@import '../../styles/all';

$active-switch-color: $color-primary;
$inactive-switch-color: $background-color-primary;
$inactive-stroke: $color-inputs;
$inactive-bg: $white;
$switch-height: 35px;
$switch-width: 80px;

.c-SimpleSwitch {
  background: $inactive-bg;
  border: 1px solid $inactive-stroke;
  border-radius: $switch-height;
  box-sizing: initial;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  min-width: $switch-width;
  outline: 0;
  padding: 1px;
  position: relative;
  transition: all 0.4s ease;
  user-select: none;

  &:after {
    background: $inactive-switch-color;
    border: none;
    border-radius: $switch-height;
    box-sizing: border-box;
    content: '';
    display: block;
    height: $switch-height;
    position: relative;
    right: 0;
    transition-duration: 0.3s;
    transition-property: all, margin, padding;
    transition-timing-function:
      cubic-bezier(0.175, 0.885, 0.32, 1.275),
      ease,
      ease;
    width: $switch-height;
  }

  &--on:after {
    background: $inactive-bg;
    border: none;
    margin-left: 45px;
  }

  &--on {
    background: $active-switch-color;
  }

  &__input {
    display: none;
  }

  &__content {
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    right: 15px;
    top: 8px;
  }

  &--on &__content {
    color: $white;
    left: 15px;
    right: 0;
  }
}
