@import '../../styles/main';

.c-MyPolicies {
  &__container {
    margin: -62px 0 15px;

    @media only screen and (min-width: 950px) {
      margin: 60px auto 15px;
      max-width: 900px;
    }

    @include tablet-and-above {
      margin-top: 0;
    }
  }

  &__policy {
    align-items: center;
    border: 1px solid $silver;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    margin: 30px 6px;

    &:hover {
      cursor: pointer;
    }

    @include tablet-only {
      margin: 20px 75px;
    }

    @include desktop-and-above {
      margin: 35px 140px;
    }

    &__icon {
      background: $color-primary;
      border: 1px solid transparent;
      border-radius: 50%;
      height: 35px;
      margin: 15px 20px 15px 25px;
      padding: 5px;
      width: 35px;

      &--device {
        padding: 6px 10px;
      }
    }

    &__reference {
      color: $color-primary;
    }

    &__expiration {
      align-items: center;
      background: $beige;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      color: $tertiary-text-color;
      display: flex;
      font-size: $secondary-font-size;
      justify-content: space-between;
      padding: 10px 25px;
      width: 100%;
    }

    &__expirationLabel {
      align-items: center;
      display: flex;

      svg {
        margin-right: 10px;
      }
    }

    &__expirationDays {
      color: $soft-red;
    }
  }
}
