@import '../../../../styles/main';

.c-Claims {
  &__activeClaims {
    background-color: $nested-form-background;
  }

  &__counterPadding {
    padding: 0 $form-field-padding;
  }
}
