@import '../../../styles/main';

.c-CarPayment {
  &__paymentSubtitle {
    color: $color-primary;
    font-size: 18px;
    line-height: 1.33em;
    margin: 31px 0;
    padding: 0 30px;
  }
}
