@import "../../styles/main";

.c-QuoteInfo {
  &__featureList {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
  }

  &__feature {
    align-content: center;
    display: inline-flex;
    margin-bottom: 6px;
  }

  &__featureIcon {
    color: $color-primary;
  }

  &__featureXIcon {
    color: $red;
  }

  &__featureText {
    color: $greyish-brown;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    margin: 0 5px;
  }

  &__featureHeader {
    color: $color-primary;
    margin: 10px 5px 2px 0;
  }

  &__readMore {
    color: $color-primary;
    margin: 10px 20px;
  }

  &__quoteTitle {
    color: $color-primary;
  }

  &__readMoreLabel {
    color: $color-primary;
    margin-right: 6px;
    text-align: left;
  }

  &__readMoreContent {
    display: flex;
    flex-direction: column;
  }
}
