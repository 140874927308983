@import '@arachas/core/lib/styles/_all.scss';

.c-NewPassword {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      padding: 8% 11.5% 6.5%;
    }
  }

  &__title {
    color: $tertiary-text-color;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.38;
    padding-top: 3.5%;
  }

  &__button {
    display: flex;
    padding-top: 4.5%;
  }

  &__fieldLabel {
    color: $tertiary-text-color;
    font-family: $font-stack;
    font-weight: 600;
    line-height: 22px;
    margin: 10px 5px;
    padding-top: 5%;
    text-align: left;

    @include tablet-and-above {
      align-items: center;
      display: inline-flex;
      width: 100%;
    }
  }

  &__label {
    @include tablet-and-above {
      padding-right: 5px;
      width: 24%;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }
}
