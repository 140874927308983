@import '../../styles/main';
@import '../../styles/commonPageStyles';

.c-AssumptionsPage {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 15% 64px;
  }

  @include desktop-and-above {
    margin: 0 auto 64px;
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
    width: 1100px;
  }

  color: $tertiary-text-color;

  &__assumptionsCard {
    background-color: transparent;

    @include tablet-and-above {
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      margin: 30px auto;
      padding: 0 30px;
      width: 600px;
    }
  }

  &__titleArea {
    padding-bottom: 10px;
    text-align: center;

    &__icon {
      margin: 10px auto;
      width: 100px;

      &--device {
        width: 68px;
      }
    }
  }

  &__note {
    color: $color-primary;
    font-size: $h4-font-size;
    font-weight: $ft-w-light;
    margin: 0 auto $medium-margin;
    margin-bottom: $small-margin;
  }

  &__declarationContent {
    font-size: $secondary-font-size;
    margin-bottom: $medium-margin;

    & ol {
      padding-left: 30px;
    }

    & ol li {
      margin-top: $small-margin;
      padding-left: $small-margin;
    }
  }

  &__tickContainer {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
    vertical-align: middle;
  }

  &__tick {
    flex-basis: 30px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  &__title {
    color: $deep-purple;
    font-size: $h4-font-size;
    padding: 10px 0;
  }

  &__subTitle {
    color: $deep-purple;
    font-size: $h4-font-size;
    padding: 15px 0;
  }

  &__subHeading {
    color: $deep-purple;
    font-size: $h4-font-size;
  }

  b {
    color: $rich-purple;
  }

  p {
    word-break: normal;
  }

  &__card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);

    .c-ThreeStateToggle__status {
      background: $rich-purple;
    }
  }

  &__contentContainer {
    padding: $light-padding;

    @include tablet-and-above {
      margin: 0 auto;
      padding: $medium-padding 10%;
      width: 100%;
    }

    @include desktop-and-above {
      padding: $medium-padding 15%;
    }
  }

  &--additionalDrivers {
    padding: 25px 0 20px 25px;
  }

  &--specifiedItems {
    padding: 20px 0;
  }

  &__namedDrivers {
    margin-left: 50px;
  }

  &__linkDisplay {
    bottom: 5px;
    position: relative;
  }

  &__confirmButtonContainer {
    margin-bottom: 20px;

    @include tablet-and-above {
      margin: auto auto 20px;
      width: 50%;
    }
  }

  &__denyButtonContainer {
    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  &__noAgreeInfo {
    color: $error-red;
    font-size: $secondary-font-size;
    margin-top: 10px;

    @include tablet-and-above {
      margin: 10px auto;
      width: 80%;
    }
  }
}
