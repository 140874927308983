@import '../../../styles/main';

.c-YourHomeForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: $form-container-width;
    }

    @include desktop-and-above {
      width: $form-container-width;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }

  &__lastRepeatingFieldContainer {
    margin-bottom: 0;
  }

  &__jointOwnerHeader {
    @include itemCountLabel;
    @include fieldContainer;
  }
}
