@import '../../../../../styles/main';

.c-PenaltyPoints {
  &__repeatingFieldsContainer {
    @include dynamicFieldsContainer;
  }

  &__offenceFieldContainer {
    text-align: left;
  }

  &__offenceContainer {
    text-align: left;
  }

  &--extraFieldContainerSpacing {
    margin-top: 30px;
  }

  &__note {
    color: $error-red;
    font-family: $font-stack;
    font-size: $tertiary-font-size;
    font-weight: $ft-w-bold;
    padding-bottom: 2px;
    text-align: left;
  }
}
