@import '../../styles/main';

.c-RenewalDates {
  @include standardPageLayout;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__buttonContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 20px;
      width: 100%;
    }
  }

  &__dateRow {
    background: $white;
    border: 1px solid $silver;
    border-radius: 15px;
    color: $tertiary-text-color;
    display: flex;
    font-size: $secondary-size;
    margin-bottom: 15px;
    padding: 15px;
  }

  &__productIcon {
    background: $rich-purple;
    border: 1px solid $rich-purple;
    border-radius: 50%;
    height: 40px;
    margin-right: 20px;
    padding: 8px;
    width: 40px;

    &--travel {
      padding: 5px 8px 8px 5px;
    }
  }

  &__date {
    color: $rich-purple;
    font-size: $h4-size;
    margin-bottom: 3px;
  }

  &__close {
    color: $red;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__iconContainer,
  &__titleContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
    }
  }

  &__title {
    color: $tertiary-text-color;
  }

  &__fieldLabel {
    color: $tertiary-text-color;
    font-family: $font-stack;
    line-height: 22px;
    padding-bottom: 5%;
    text-align: left;
  }

  &__error {
    color: $light-red;
    text-align: center;
  }

  @include mobile-only {
    &__container {
      margin-top: 30px;
    }
  }
}
