#Brand__Logo {
  @media screen and (max-width: 600px) {
    height: 100%;
  }

  @media screen and (min-width: 600px) {
    height: 30px;
    object-fit: contain;
    width: 160px;
  }
}
