@import '../../../../styles/main';
@import '../../../../styles/commonPageStyles';

.c-PaymentsFrequency {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 15% 10%;
  }

  @include desktop-and-above {
    margin: 0 auto 10%;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 1100px;
  }

  &__buttonGroupLabelMargin {
    margin-bottom: 5px;
  }

  &__paddedContainer {
    padding: 0;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
  }

  &__card {
    @include noPaddingCardContainer;

    padding: $medium-padding 0;

    @include tablet-and-above {
      padding: 40px 0 $medium-padding;
    }
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 auto;
      width: 100%;
    }

    @include desktop-and-above {
      width: 740px;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }
}
