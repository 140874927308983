@mixin tablet-and-above {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop-and-above {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-width}) and (max-width: calc(#{$desktop-width} - 1px)) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: calc(#{$tablet-width} - 1px)) {
    @content;
  }
}

// TYPOGRAPHY
@mixin fontBase($f-size, $f-family, $color) {
  @include fontSize($f-size);

  color: $color;
  font-family: $f-family;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  // font-size: calculateRem($size);
}

// Needs work enlarging all standard text
@function calculateRem($size) {
  $remSize: $size / 14px;

  @return #{$remSize}rem;
}

// CARDS
@mixin cardBase($bg-color, $border-color) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}
