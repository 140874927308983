@import '../../styles/main';

.c-GetQuoteThankYouPage {
  @include standardPageLayout;

  color: $tertiary-text-color;
  font-size: $primary-font-size;
  font-stretch: normal;
  font-style: normal;
  font-weight: $ft-w-normal;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: center;

  @include tablet-and-above {
    margin: auto;
  }

  &__icon {
    margin: 0 auto $medium-margin;
    width: 50%;

    @include tablet-and-above {
      padding-bottom: 5%;
      padding-top: 5%;
      width: 15%;
    }
  }

  &__firstParagraph {
    margin: auto;
    padding-top: 10%;
    width: 226px;

    @include tablet-and-above {
      padding-top: 5%;
    }
  }

  &__secondParagraph {
    margin: 0 auto $medium-margin;
    padding-top: 10%;
    width: 150px;

    @include tablet-and-above {
      padding-bottom: 3%;
      padding-top: 3%;
    }
  }

  &__buttonContainer {
    justify-content: center;

    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }

  .c-Button {
    font-weight: $ft-w-normal;
    padding: 5px 20px;

    @include tablet-and-above {
      padding: 10px 70px;
    }
  }
}
