@import "../../../styles/main";

.c-Message {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: $ft-w-500;
  padding: 20px 27px;

  > * {
    flex: 1;
  }

  &__time {
    font-size: $primary-font-size;
    max-width: 4em;
    text-align: right;
    width: 4em;
  }

  &__messageContainer {
    padding-right: 16px;
  }

  &__subject {
    color: $primary-color;
    font-size: $primary-font-size;
    line-height: 1.25;
    margin-bottom: 6px;
  }

  &__message {
    font-size: $secondary-font-size;
    line-height: 1.43;
  }
}
