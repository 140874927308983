@import '../../styles/all';

.c-AccordionText {
  &__header {
    align-items: center;
    display: inline-flex;
    width: 100%;

    &--marginTop {
      margin-top: 10px;
    }

    &--marginLeft {
      margin-left: 20px;
    }
  }

  &__text {
    flex-basis: auto;
    max-width: 75%;
  }

  &__labelText {
    color: $rich-purple;
    font-weight: $ft-w-bold;
    padding-right: 8px;
    text-align: left;
  }

  &__button-container {
    font-size: 19.5px;
    margin: 0 2px;

    &--alignRight {
      margin-left: auto;
    }

    &--alignCenter {
      margin: 0 auto;
    }
  }

  &--underline {
    text-decoration: none;
  }

  &__secondLineLabel {
    font-weight: normal;
  }
}
