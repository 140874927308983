@import './main';

.g-CommonPageStyles {
  &__pageTitle {
    margin-bottom: 25px;
  }

  &__showOnDesktopOnly {
    display: none;

    @include tablet-and-above {
      display: block;
    }
  }

  &__hideOnDesktop {
    display: block;

    @include tablet-and-above {
      display: none;
    }
  }

  &__notVisibleDesktop {
    visibility: visible;

    @include tablet-and-above {
      visibility: hidden;
    }
  }

  &__desktopTitle {
    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    font-family: $font-stack;
    font-size: $h1-size;
    font-weight: $ft-w-light;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.38;
    padding-top: 10px;
  }

  &__logo {
    @include tablet-and-above {
      height: 34px;
      margin: 0 auto;
      text-align: center;
      width: 280px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .g-CommonPageStyles {
    &__pageTitle {
      margin: auto;
      width: 600px;
    }
  }
}
