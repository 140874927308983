@import '../../styles/main';

.c-SpecifiedItems {
  &__closeIcon {
    float: right;
  }

  &__itemNumberContainer {
    display: flex;
    justify-content: space-between;
  }

  &__itemPromptAndNumberContainer {
    margin-bottom: $medium-margin;
  }

  &__itemPromptContainer {
    margin-top: $small-margin;
  }
}
