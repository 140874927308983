@import '../../styles/main';

.c-TravelDestinationPage {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    margin-bottom: 86px;
    margin-top: 40px;
  }

  &__link {
    display: flex;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__form {
    @include tablet-and-above {
      padding-top: 30px;
    }
  }

  &__fontColor {
    @include tablet-and-above {
      color: $rich-purple;
    }
  }

  &__resendEmailMessageContainer {
    margin-top: 10px;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0 0 30px;

    @include tablet-and-above {
      padding: 0;
      width: 50%;
    }
  }

  &__icon {
    height: 79px;
    width: 105px;

    @include tablet-and-above {
      height: 81px;
      width: $icon-width-desktop;
    }
  }

  &__subHeading {
    font-size: 14px;
    padding-top: 10px;
  }
}
