@import '../../styles/main';

.c-ViewTravelQuotePage {
  @include pageWithQuoteDisplayLayout;

  &__card {
    @include noPaddingCardContainer;

    padding: $form-field-padding 0;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }

  &__quoteInfoContainer {
    margin-bottom: $medium-margin;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
  }

  &__readMoreLabel {
    color: $rich-purple;
    font-weight: $ft-w-bold;
    margin-right: 5px;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: auto;
      width: 100%;
    }
  }

  &__logo {
    padding-bottom: $small-margin;
  }

  &__chubbLogo {
    height: 20px;
    vertical-align: middle;
    width: 130px;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__eligibilityWarningTitle {
    font-size: 20px;
    font-weight: $ft-w-bold;
    text-align: center;
  }

  &__eligibilityWarningText {
    padding: 5px 0 0;
    text-align: center;
  }

  .c-AccordionCard__container {
    border-width: 0;
  }
}
