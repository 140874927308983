@import '../../styles/main';

.c-Logout {
  margin: auto;
  width: 80%;

  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      margin: auto;
      padding: 0;
      width: 700px;
    }
  }

  &__text {
    font-size: 16px;
    margin: auto;
    padding: 20% 0;
    text-align: center;

    @include tablet-and-above {
      padding: 10% 0;
    }
  }

  &__buttonContainer {
    padding-left: 5.6%;
    padding-right: 5.6%;
    padding-top: 7.5%;
  }
}
