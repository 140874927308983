@import "../../styles/main";

$desktop-padding-top: 70px;

.c-DocumentsLandingPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }

    @include tablet-and-above {
      width: 100%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__buttonContainer {
    margin-top: $medium-margin;

    @include tablet-and-above {
      margin: auto;
      padding-top: 10%;
      width: 400px;
    }
  }

  &__deviceIcon {
    height: auto;
    margin: auto;
    width: $device-icon-mobile-width;

    @include tablet-and-above {
      height: auto;
      width: $device-icon-desktop-width;
    }
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;
  }

  &__buttonSpacing {
    margin-top: 3%;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 100%;
    }
  }
}

@media only screen and (min-width: $desktop-form-width) {
  .c-DocumentsLandingPage {
    margin: auto;
    width: 100%;

    &__customTitleTextContainer {
      margin: auto;
      width: $desktop-form-width;
    }

    &__customTitleText {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin-left: 5px;
    }
  }
}
