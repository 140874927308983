@import '../../../styles/all';

.c-Label {
  font-family: $font-stack;
  font-size: $quinary-size;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
}
