@import '../../styles/main';

.c-ManageMyInsuranceDashboard {
  @include standardPageLayout;

  &__title {
    font-size: $primary-font-size;
  }

  &__spinnerContainer {
    @include animatedSpinner;

    margin-top: 20px;
  }

  &__buttonContainer {
    .c-ButtonWithIcons__rightIcon {
      width: 15px;
    }

    @include tablet-and-above {
      margin: 0 auto;
      padding-top: 20px;
      width: 400px;
    }
  }

  &__buttonSpacing {
    padding-top: 3%;

    @include tablet-and-above {
      margin: auto;
      width: 100%;
    }
  }

  &__button {
    justify-content: center;
    width: 0;
  }
}

@media only screen and (min-width: 600px) {
  .c-ManageMyInsuranceDashboard {
    width: 100%;
  }
}
