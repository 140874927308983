@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

$font-stack: 'Montserrat', 'Roboto', Tahoma, Helvetica, Arial, sans-serif;

$primary-size: 14px;
$secondary-size: 12px;
$tertiary-size: 11px;
$quaternary-size: 10px;
$quinary-size: 16px;

$h1-size: 24px;
$h2-size: 20px;
$h3-size: 18px;
$h4-size: 16px;

// Navigation Font Size
$navigation-font-size: 16px;

// Font Weights
$ft-w-light: 300;
$ft-w-normal: 400;
$ft-w-500: 500;
$ft-w-bold: 600;
