@import '../../styles/main';

.c-WebsiteLoadingPage {
  &__pageContainer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__iconAndSpinnerContainer {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 170px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__logoContainer {
    height: 34px;
    margin-bottom: 20px;
    width: 280px;
  }
}
