@import '../../styles/main';

.c-VerifyEmail {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      margin: auto;
      padding: 0;
      width: 100%;
    }
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__button {
    display: flex;
    padding-top: 4.5%;
  }

  &__link {
    display: flex;
    padding-top: 2.5%;

    &:hover {
      cursor: pointer;
    }
  }
}
