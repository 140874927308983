@import '../../styles/main';

.c-MarketingConsentForm {
  @include fontBase($primary-font-size, $font-stack, $tertiary-text-color);
  @include standardPageLayout;

  &__visibleText {
    margin-bottom: 15px;
  }

  &__readMoreContent {
    margin-bottom: $medium-margin;
  }

  &__giveConsentSwitch {
    display: flex;
    flex-direction: row;
    font-weight: $ft-w-bold;
    height: 40px;
    justify-content: space-between;
    line-height: 1.38em;
    margin: $medium-margin 0;
  }

  &--spaceBellow {
    margin-bottom: $medium-margin;
  }

  &--bold {
    font-weight: $ft-w-bold;
  }

  &__consentNextButton {
    margin-top: 40px;
  }

  &--letterSpaceBefore {
    margin-left: 0.6em;
  }

  &__consentSubtitle {
    color: $color-primary;
    font-size: $h4-font-size;
    font-weight: $ft-w-light;
    line-height: 1.33em;
    margin-bottom: $medium-margin;
  }

  &__checkboxContainer {
    font-size: $primary-font-size;
    margin-bottom: $medium-margin;
  }

  &__contactTypeWarning {
    margin-top: 40px;
  }

  .c-Checkbox__text {
    line-height: 29px;
  }
}
