@import '../../../../styles/main';

.c-GoCardless {
  padding: 0;

  &__link {
    color: $primary-color;
    font-weight: $ft-w-500;
    text-decoration: underline;
  }

  &__divider {
    margin: 30px 0;
  }
}
