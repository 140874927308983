@import "../../../styles/main";

.c-MessagesNavigationItem {
  position: relative;

  &__messagesIndicator {
    background: $macAndCheese;
    border-radius: 50%;
    display: block;
    height: 7px;
    left: 17px;
    position: absolute;
    top: 21px;
    width: 7px;
  }
}
