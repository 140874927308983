@import '../../styles/main';

.c-ViewMultiplePoliciesPage {
  @include standardPageLayout;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;

    @include tablet-and-above {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: 500;
    }
  }

  &__buttonContainer {
    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 400px;
    }
  }

  &__buttonSpacing {
    margin-top: 3%;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 100%;
    }
  }

  &__noPoliciesMessage {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-top: $medium-margin;
    text-align: center;
  }

  &__titleText {
    @include mobile-only {
      .c-TitleWithUnderLine__text {
        color: $greyish-brown;
        font-weight: $ft-w-normal;
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (min-width: $desktop-form-width) {
  .c-ViewMultiplePoliciesPage {
    width: 100%;

    &__customTitleTextContainer {
      margin: auto;
      width: $desktop-form-width;
    }

    &__customTitleText {
      margin-left: 5px;
    }
  }
}
