@import '../../styles/main';

.c-CancelPolicy {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 600px;
  }

  label {
    font-size: $primary-font-size;
  }

  textarea {
    font-size: $secondary-font-size;
    padding: 19px 12px;
  }

  &__titleContainer {
    text-align: left;
  }

  &__subHeading {
    margin: 12px 0 30px;
  }

  .c-DesktopCard {
    margin: 30px auto;
  }
}
