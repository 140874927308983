@import 's-colors';
@import 's-settings';
@import 's-typography';

@mixin customPageLayout {
  width: 100%;
}

@mixin standardPageLayout {
  @include mobile-only {
    padding: 7.5%;
  }

  @include tablet-and-above {
    margin: 0 auto;
    padding: 50px 0 40px;
    width: 600px;
  }
}

@mixin pageWithQuoteDisplayLayout {
  padding: 7.5% 7.5% $quote-display-footer-height;
}

@mixin fieldLabel {
  @include fieldLabelFont;

  color: $tertiary-text-color;
  line-height: 1.31;
}

@mixin itemCountLabel {
  color: $color-primary;
  font-family: $font-stack;
  font-size: $h4-font-size;
  font-weight: $ft-w-bold;
}

@mixin fieldContainer {
  margin-bottom: $medium-margin;
  text-align: left;
}

@mixin dynamicFieldsContainer {
  background-color: $nested-form-background;
  border-bottom: 1px solid $nested-form-border;
  border-top: 1px solid $nested-form-border;
}

@mixin noPaddingCardContainer {
  background-color: white;
}

@mixin insuranceIcon {
  /**
     * 80.4px from Zeplin / 316.5px width of desktop card
     * at viewport width of 375px
     */
  height: 25.4%;

  /**
   * 101px from Zeplin / 316.5px width of desktop card
   * at viewport width of 375px
   */
  width: 31.91%;
}

@mixin animatedSpinner {
  align-items: center;
  display: flex;
  height: 100%;
  margin-top: 20px;
  width: 100%;
}

@mixin loginJourneyPagePadding {
  padding: 31px 0;
}

@mixin e-InputFieldStates {
  background-color: $white;
  border: $border-default;
  box-sizing: border-box;
  color: $tertiary-text-color;
  display: flex;
  flex-flow: row nowrap;
  font-family: $font-stack;
  font-size: 16px;
  height: 56px;
  margin: 4px 0;
  outline: none;
  padding: 0 16px;
  position: relative;
  width: 100%;

  &:focus,
  &:active {
    border: $border-focus;
  }

  &:disabled {
    background-color: #e8e8e8;
    border: $border-disabled;
  }

  &--read-only {
    border: $border-read-only;
  }

  &--error,
  &--error:focus,
  &--error:active {
    border: $border-error;
  }
}
