@import '../../../styles/main';

.c-PremiumBreakdownRow {
  &__rowContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: $small-margin;
    width: 100%;
  }

  &__price {
    @include premiumBreakdownFont;

    align-self: flex-end;
    color: $color-primary;
    font-weight: $ft-w-light;
    text-align: right;
    width: 25%;
  }

  &__label {
    @include premiumBreakdownFont;

    color: $tertiary-text-color;
    text-align: left;
    width: 75%;

    &--bold {
      font-weight: $ft-w-bold;
    }
  }
}
