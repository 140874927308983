@import '../../styles/main';

.c-MotorClaims {
  &__activeMotorClaims {
    background-color: $nested-form-background;
  }

  &__repeatingFieldsContainer {
    @include dynamicFieldsContainer;

    margin-bottom: $medium-margin;
    padding: $form-field-padding;
  }

  &__fieldLabeLessPadding {
    margin: 10px 5px;
    text-align: left;
  }

  &__counterPadding {
    padding: 0 $form-field-padding;
  }

  &__itemNumberContainer {
    margin-bottom: $medium-margin;
  }
}
