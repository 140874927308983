@import '../../styles/main';

.c-SubmitClaimDefaultPage {
  @include standardPageLayout;

  display: flex;
  flex-direction: column;
  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;

  &__customTitleTextContainer {
    margin: auto;
    width: 600px;
  }

  &__customTitleText {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    margin-left: 5px;
    padding-top: 20px;

    @include mobile-only {
      display: none;
    }
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    text-align: center;
    width: $icon-width-mobile;

    > * {
      height: 100%;
    }

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__customTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    text-align: center;
  }

  &__content {
    margin: auto;
    width: 400px;

    @include mobile-only {
      width: 100%;
    }
  }

  &__textContent {
    margin-bottom: 20px;
    width: 100%;
  }

  &__textAreaOne {
    margin-bottom: 20px;
    width: 400px;

    @include mobile-only {
      width: 100%;
    }
  }

  &__address {
    margin-top: 20px;
  }

  &__phoneButtonContainer {
    margin: auto;

    @include tablet-and-above {
      margin: auto;
      width: 400px;
    }
  }

  &__outOfIrelandContentText {
    padding: 20px 0;
  }
}
