@import '@arachas/core/lib/styles/_all.scss';

.c-AgentLatestQuotes {
  background-color: $off-white;
  margin: auto;
  max-width: 1300px;
  padding: 75px 10px 35px;
  width: 100%;

  &__titleContainer {
    color: $tertiary-text-color;
    margin: 10px;

    @include tablet-and-above {
      max-width: 1000px;
      padding: 0 0 35px;
      width: 100%;
    }
  }

  &__titleText {
    color: $tertiary-text-color;
    font-size: 20px;
    padding-left: 50px;
    text-align: center;

    @include tablet-and-above {
      color: $tertiary-text-color;
      font-size: 24px;
      text-align: left;
    }
  }

  &__iconContainer {
    float: left;
    width: 30px;
  }

  &__quotesContainer {
    box-shadow: 1px 1px 5px -1px #ddd;
    color: $tertiary-text-color;
    margin: 10px;
    margin-bottom: 40px;

    .c-QuoteRow:first-child {
      background-color: $background-color-secondary;
    }

    .c-QuoteRow:first-child {
      background: $white;
      border-bottom: 1px solid $color-primary;
    }

    .c-QuoteRows {
      background-color: $white;
      display: grid;
      grid-gap: 0;
      grid-template-columns: 110px 8fr 5fr 9fr 9fr 9fr 9fr 8fr 3fr 5fr;
      margin: 0 auto;

      &__column,
      &__column__link {
        border-bottom: 1px solid $gray-94;
        border-right: 1px solid $color-primary;
        color: $light-gray;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        justify-content: center;
        padding: 5px 10px 5px 20px;
        text-align: left;

        &__last {
          border-right: none;
        }
      }

      &__column__link:hover {
        cursor: pointer;
        font-weight: bold;
      }

      &__column--header {
        border-bottom: 1px solid $color-primary;
        color: $tertiary-text-color;
        font-size: 16px;
        padding: 10px 9px 10px 19px;

        &__last {
          border-right: none;
        }
      }

      &__column--header:last-child {
        border-right: none;
      }

      &__loadMore {
        padding-left: 20%;
        padding-right: 20%;
        text-align: center;
        width: 100%;

        .c-Button--quaternary {
          width: 90%;
        }
      }
    }
  }
}
