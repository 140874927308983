@import '../../styles/main';

.c-SubmitClaimPage {
  @include standardPageLayout;

  display: flex;
  flex-direction: column;
  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;

  &--alignText {
    text-align: center;
  }

  &__customTitleTextContainer {
    margin: auto;
    width: 100%;
  }

  &__content {
    margin: auto;
    width: 100%;
  }

  &__customContent {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__bulletHeading {
    color: $rich-purple;
    font-weight: bold;
    margin-left: 10px;
  }

  &__customBullets {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
  }

  &__heading {
    color: $rich-purple;
    font-weight: bold;
    margin-top: 25px;
  }

  &__button {
    display: flex;
    margin-bottom: 86px;
  }

  &__buttonContainer {
    margin: 10% 0 0;

    @include tablet-and-above {
      padding-top: 10%;
    }
  }

  &__customButtonContainer {
    margin: auto;
    width: 100%;

    @include tablet-and-above {
      width: 600px;
    }
  }

  &__accordions {
    font-family: $font-stack;
    margin: auto;
    width: 100%;

    @include tablet-and-above {
      width: 600px;
    }
  }

  &--spacing {
    margin-bottom: 30px;
  }

  &__customTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    text-align: center;
  }

  &__titleText {
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: left;

    @include tablet-and-above {
      margin-bottom: 0;
      margin-left: 20px;
      width: $desktop-form-width;
    }
  }

  &__titleSubTitleContainer {
    width: 100%;

    @include tablet-and-above {
      margin: auto;
      width: $desktop-form-width;
    }
  }

  &__titleSubTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    margin-left: 20px;
    padding-bottom: 10px;
    text-align: left;
  }

  &__container {
    margin-top: 5%;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    text-align: center;
    width: $icon-width-mobile;

    > * {
      height: 100%;
    }

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  .c-Button--secondary {
    background-color: transparent;
  }

  ul {
    list-style: none;
    margin-top: 10px;
  }

  ul li:before {
    color: $rich-purple;
    content: "\2022";
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }

  &__phoneButtonContainer {
    margin: auto;

    @include tablet-and-above {
      margin: auto;
      width: 100%;
    }
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;

    @include tablet-and-above {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin: auto;
      width: 30%;
    }
  }

  &__address {
    margin-top: 20px;
  }

  @media only screen and (min-width: 900px) {
    .c-SubmitClaimPage {
      &__phoneButtonContainer {
        margin: auto;
      }

      &__customContent {
        margin-bottom: 20px;
        margin-left: 55px;
        margin-top: 20px;
      }

      &__heading {
        margin: auto;
        padding-top: 20px;
        width: 80%;
      }

      &__customList {
        margin: auto;
        width: 85%;
      }
    }
  }
}

@media only screen and (min-width: $desktop-form-width) {
  .c-SubmitClaimPage {
    margin: auto;
    width: 100%;

    &__customTitleTextContainer {
      margin: auto;
      width: $desktop-form-width;
    }

    &__customTitleText {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin-left: 5px;
    }
  }
}
