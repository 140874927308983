@import '../../styles/main';
@import '../../styles/commonPageStyles';

.c-SummaryPage {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 15% 64px;
  }

  @include desktop-and-above {
    margin: 0 auto 64px;
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
    width: 1100px;
  }

  color: $tertiary-text-color;

  &__titleArea {
    padding-bottom: 10px;
    text-align: center;

    &__icon {
      margin: 10px auto;
      width: 100px;

      &--device {
        width: 68px;
      }
    }
  }

  &__tickContainer {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
    vertical-align: middle;
  }

  &__tick {
    flex-basis: 30px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  &__title {
    font-size: $h4-font-size;
    padding: $large-padding 0;
  }

  b {
    color: $rich-purple;
  }

  p {
    word-break: normal;
  }

  &__card {
    @include noPaddingCardContainer;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  }

  &__contentContainer {
    padding: $light-padding;

    @include tablet-and-above {
      margin: 0 auto;
      padding: $medium-padding 10%;
      width: 100%;
    }

    @include desktop-and-above {
      padding: $medium-padding 15%;
    }
  }

  &--additionalDrivers {
    padding: 25px 0 20px 25px;
  }

  &--specifiedItems {
    padding: 20px 0;
  }

  &__namedDrivers {
    margin-left: 50px;
  }
}
