@import '../../../styles/all';

.componentContainer {
  height: 115px;
  margin: 0 auto;
  width: 80px;
}

.dots {
  fill: $off-white;
  height: 88px;
  position: absolute;
  width: 88px;
  z-index: 1;

  @include tablet-and-above {
    fill: white;
  }
}

.success-checkmark2 {
  height: 115px;
  margin: 0 auto;
  position: relative;
  width: 80px;

  svg {
    left: 0;
  }

  .check-icon2 {
    border: 4px solid $bright-green;
    border-radius: 50%;
    box-sizing: content-box;
    height: 80px;
    position: relative;
    width: 80px;

    &:after {
      animation: rotate-circle2 2s 2 ease-in-out forwards;
      border-radius: 0 100px 100px 0;
      left: 30px;
      top: 0;
      transform-origin: 0 50%;
      width: 60px;
    }

    &:before,
    &:after {
      background: $off-white;
      content: '';
      height: 100px;
      position: absolute;
      transform: rotate(-90deg);

      @include tablet-and-above {
        background: white;
      }
    }

    .icon-line2 {
      background-color: $bright-green;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 10;

      &.line-tip2 {
        animation: icon-line-tip2 0.75s 3.8s both;
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
      }

      &.line-long2 {
        animation: icon-line-long2 0.75s 3.8s both;
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
      }
    }

    .icon-circle2 {
      border: 4px solid rgba(76, 175, 80, 0.5);
      border-radius: 50%;
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 0;
    }
  }
}

@keyframes rotate-circle2 {
  0% {
    opacity: 0.8;
    transform: rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip2 {
  0% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  54% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  70% {
    left: -8px;
    top: 37px;
    width: 50px;
  }

  84% {
    left: 21px;
    top: 48px;
    width: 17px;
  }

  100% {
    left: 14px;
    top: 45px;
    width: 25px;
  }
}

@keyframes icon-line-long2 {
  0% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  65% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  84% {
    right: 0;
    top: 35px;
    width: 55px;
  }

  100% {
    right: 8px;
    top: 38px;
    width: 47px;
  }
}
