@import '../../styles/main';

.c-GetGenericQuotePageForm {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 600px;
  }

  &__fieldLabel {
    @include fieldLabel;
  }

  &__accordions {
    font-family: $font-stack;
  }

  &--spacing {
    margin-bottom: 30px;
  }

  &__button {
    margin-top: 25px;

    @include tablet-and-above {
      text-align: right;

      button {
        width: 100%;
      }
    }
  }

  &__legalTextContainer {
    padding-top: 11.1%;

    @include tablet-and-above {
      padding-top: 50px;
    }
  }

  &__subHeading {
    padding-bottom: 40px;
  }

  &__noPaddingFormContainer {
    @include noPaddingCardContainer;
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }
}
