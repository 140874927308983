/// - [#600060] - $rich-purple
///@type Color
$rich-purple: #600060;

/// - [#600460] - $deep-purple
///@type Color
$deep-purple: #600460;

/// - [#053c59] - $dark-blue.
/// @type Color
$dark-blue: #053c59;

/// - [#013c5c] - $alternative-dark-blue.
/// @type Color
$alternative-dark-blue: #013c5c;

/// - [#fff] - $white.
/// @type Color
$white: #fff;

/// - [#474747] - $tertiary-text-color
/// @type Color
$tertiary-text-color: #474747;

/// - [#81bc29] - $verification-green.
/// @type Color
$verification-green: #81bc29;

/// - [#f5f5f5] - $nested-form-background:
/// @type Color
$nested-form-background: #f5f5f5;

/// - [#d4d4d5] - $nested-form-border:
/// @type Color
$nested-form-border: #d4d4d5;

/// - [#fafbfc] - $off-white.
/// @type Color
$off-white: #fafbfc;

/// - [#a0a1a3] - $mid-grey:
/// @type Color
$mid-grey: #a0a1a3;

/// - [#ff6969] - $light-red:
/// @type Color
$light-red: #ff6969;

/// - [#ccc] - $silver - It's silver!
/// @type Color
$silver: #ccc;

/// - [#1081a6] - $blue-notification - It's silver!
/// @type Color
$blue-notification: #1081a6;

/// - [#ef6c00] - $dark-orange - It's silver!
/// @type Color
$dark-orange: #ef6c00;

/// - [#616365] - $light-gray - It's light-gray!
/// @type Color
$light-gray: #616365;

/// monochrome
/// - [#6e7881] - $font-color-secondary - Secondary text of lower importance to the primary content.
/// @type Color
$font-color-secondary: #6e7881;

/// - [#da333e] - $error-red - color to be used on errors
/// @type Color
$error-red: #da333e;

/// - [#4183c4] - $links-color - color to be used on errors
/// @type Color
$links-color: #4183c4;

///@type Color
$primary-color: $rich-purple;

///@type Color
$dark: #352135;

///@type Color
$color-primary-dark: #410050;

/// - [#868686] - $grey-light - It's grey-light!
/// @type Color
$grey-light: #868686;

/// @type Color
$greyish-brown: #474747;

/// @type Color
$custom-white: #e5e5e5;

/// @type Color
$macAndCheese: #f2b53b;

/// @type Color
$darkBackground: #352135;

///@type Color
$darkishGrey: #616364;

///@type Color
$customDark: #484848;

$border-default: 1px solid #a0a1a3;

$border-focus: 1px solid $color-primary;

$border-read-only: 1px solid  $gray-94;

$border-disabled: 1px solid $white-gray;

$border-error: 1px solid $color-error;
