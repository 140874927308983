@import "../../styles/main";

.c-ViewMultipleQuotes {
  @include pageWithQuoteDisplayLayout;

  color: $tertiary-text-color;

  &__title {
    padding-bottom: 20px;
  }

  &__logoAllianz {
    width: 100px;
  }

  &__logoZurich {
    width: 100px;
  }

  &__logoAxa {
    height: 40px;
  }

  &__logoAviva {
    height: 50px;
    width: 70px;
  }

  &__subHeading {
    font-size: $primary-font-size;
    margin: 20px 0 25px;
    text-align: center;
  }

  &__quoteInfoContainer {
    margin-bottom: $medium-margin;
  }

  &__contentContainer {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
    padding: 20px 20px 10px;

    @include tablet-and-above {
      margin: auto;
      width: 100%;
    }
  }

  &__excessContainer {
    margin: 20px 10px;
  }

  &__consentSection {
    margin: 10px 0;
  }

  &__buySection {
    display: inline-flex;
    margin-top: 10px;
    width: 100%;
  }

  &__quotePriceContainer {
    margin: auto;
  }

  &__quotePrice {
    color: $color-primary;
    display: inline-flex;
  }

  &__buyButtonContainer {
    margin: auto;
  }

  &--excessValueLabel {
    margin-bottom: 10px;
  }

  &__premiumBreakdown {
    padding-top: 5%;
  }

  &__featureItem {
    display: flex;
    margin-bottom: 10px;
  }

  &__bullet {
    padding-right: 10px;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $small-margin;
  }

  &__beforeText {
    color: $black;
    margin-top: 15px;
    padding: 13px 13px 0;
    text-align: center;
  }

  &__quoteDisplayContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .c-Button {
      min-height: 40px;
      width: 120px;
    }
  }

  &__price {
    color: $deep-purple;
    font-size: $h3-font-size;
    font-weight: $ft-w-bold;
  }
}
