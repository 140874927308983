.c-Documents {
  &__buttonContainer {
    padding-left: 5.6%;
    padding-right: 5.6%;
    padding-top: 7.5%;
  }

  &__buttonSpacing {
    padding-top: 3%;
  }

  &__button {
    justify-content: center;
    width: 0;
  }
}
