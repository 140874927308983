@import '../../styles/main';

.c-MtaDefaultThankYou {
  @include standardPageLayout;

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 27px;

  @include tablet-and-above {
    margin: auto;
    padding: 60px 0;
    width: 100%;
  }

  @include desktop-and-above {
    padding: 0;
    text-align: left;
    width: 400px;

    &__thankYouPageTextContainer,
    &__buttonContainer {
      width: 100%;
    }

    &__thankYouPageTextContainer > * {
      margin: auto;
    }
  }

  &__primaryTextContainerOne {
    display: flex;
    justify-content: left;
    margin-top: 0;

    @include desktop-and-above {
      display: inline-block;
      margin: 0 0 20px;
    }
  }

  &__primaryTextContainerTwo {
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
  }

  &__primaryTextContainerThree {
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
  }

  &____primaryTextContainerFour {
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
  }

  &__buttonContainer {
    width: 100%;

    > * {
      display: inline-block;
      width: 100%;
    }
  }

  &__buttonSpacing {
    padding-top: 20px;

    @include desktop-and-above {
      margin: auto;
    }
  }

  &__contentText {
    @include primaryTextFont;

    color: $tertiary-text-color;
    margin-bottom: $medium-margin;
    text-align: left;
    width: 100%;

    &--contentSpacing {
      padding-top: 10%;
    }
  }

  @include desktop-and-above {
    .c-Button {
      width: 100%;
    }
  }

  .c-Button--secondary {
    background-color: transparent;
  }
}
