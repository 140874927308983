@import '../../styles/main';

$desktop-padding-top: 70px;

.c-ManageInsuranceProductPage {
  @include standardPageLayout;

  width: 100%;

  @include mobile-only {
    .c-TitleWithUnderLine__text:after {
      content: none;
    }
  }

  &__buttonContainer {
    margin: auto;

    @include tablet-and-above {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      width: 400px;
    }

    .c-ButtonWithIcons__rightIcon {
      width: 15px;
    }
  }

  &__customTitleTextContainer {
    @include tablet-and-above {
      margin: auto;
      width: $desktop-form-width;
    }
  }

  &__customTitleText {
    @include tablet-and-above {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin-left: 5px;
    }
  }

  &__buttonSpacing {
    margin-top: 3%;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 100%;
    }
  }

  &__button {
    justify-content: center;
    width: 0;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__titleText {
    @include mobile-only {
      .c-TitleWithUnderLine__text {
        color: $tertiary-text-color;
        font-weight: $ft-w-normal;
        margin-top: 20px;

        @include tablet-and-above {
          color: $greyish-brown;
          display: block;
          font-size: $h1-size;
          font-weight: $ft-w-500;
          text-align: center;
        }
      }
    }

    @include tablet-and-above {
      order: 1;
    }
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;
  }
}
