@import '../../styles/main';

.c-DesktopHeader {
  align-items: center;
  background-image: linear-gradient($color-primary, $color-primary-dark);
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-content: space-between;
  max-height: 90px;
  min-height: 90px;
  padding: 10px 7.5%;
  text-align: center;

  &__logo {
    color: white;
    margin-top: 10px;
    text-align: center;
  }

  &__logoutLabel {
    color: white;
    font-size: $primary-font-size;
    font-weight: $ft-w-bold;
    max-width: 100px;
    min-width: 100px;
    padding-top: 10px;
  }
}

.c-SubHeading {
  background-color: $color-primary-dark;
  display: flex;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  padding: 9px 7.5% 0;
  text-align: center;

  &__linksContainer {
    display: flex;
    flex-direction: row;
    max-width: 342px;
    position: relative;
    width: 342px;
  }

  &__dropdownRow {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    position: relative;
  }

  &__dropdownRowUnderLink {
    border-bottom: 5px solid $light-red;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    position: relative;

    .c-SubHeading__dropdownMenu {
      top: calc(100% + 5px);
    }
  }

  &__dropdownMenu {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
  }

  &__dropdownRowUnderLink {
    border-bottom: 5px solid $light-red;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: relative;

    .c-SubHeading__dropdownRow {
      top: calc(100% + 20px);
    }
  }

  &__item {
    background-color: $white;
    color: $tertiary-text-color;
    cursor: pointer;
    padding: 20px 5px 0 14px;
    text-align: left;
    width: 100%;

    &:last-child {
      padding-bottom: 20px;
    }
  }

  &__link {
    color: white;
    font-size: $secondary-font-size;
    font-weight: $ft-w-normal;
  }

  &__underLineLink {
    border-bottom: 5px solid $light-red;
    color: white;
    font-size: $secondary-font-size;
    font-weight: $ft-w-normal;
  }

  &__paddingCheveron {
    color: white;
    padding-left: 10px;
    padding-top: 1px;
  }

  a {
    color: white;
  }
}
