@import "../../styles/main";

.directions_car-24px {
  height: 16px;
  object-fit: contain;
  width: 18px;
}

.c-WalletLandingPage {
  background-color: $off-white;
  padding: 0;

  > * {
    padding: 7.5%;

    &:first-of-type {
      padding-bottom: 60px;
    }

    &:last-of-type {
      padding-top: 30px;

      @include tablet-and-above {
        padding-top: 0;
      }
    }
  }

  @include tablet-and-above {
    &__TopContainer {
      padding-top: 70px;
    }
  }

  @include mobile-only {
    &__TopContainer {
      background: $darkBackground;
    }
  }

  &__WelcomeContainer {
    color: $tertiary-text-color;
    margin-bottom: 30px;

    @include tablet-and-above {
      margin: auto;
      max-width: 900px;
      width: 100%;
    }

    &__latestQuotes {
      background-color: $white;
      border: 2px solid $rich-purple;
      color: $rich-purple;
      display: block;
      float: right;
      font-size: 12px;
      font-weight: bold;
      padding: 12px 30px;
    }

    .c-TitleWithUnderLine {
      max-width: 300px;
    }
  }

  &__spinnerContainer {
    @include animatedSpinner;
  }

  &__WelcomeText {
    color: $off-white;
    font-size: 24px;
    line-height: 20px;
    text-align: center;

    @include tablet-and-above {
      color: $color-primary;
      font-size: 24px;
      text-align: left;
    }
  }

  &__WelcomeLine {
    display: none;

    @include tablet-and-above {
      background-color: $color-primary;
      display: block;
      height: 1px;
      margin-bottom: 17px;
      margin-top: 7px;
      text-align: left;
      width: 50px;
    }
  }

  &__WelcomeSubtext {
    color: $off-white;
    font-size: $secondary-font-size;
    text-align: center;

    @include tablet-and-above {
      font-size: $h3-font-size;
      font-weight: $ft-w-light;
      margin: 24px 0 40px;
      text-align: left;
    }
  }

  &__quotesTitle {
    color: $color-primary;
    font-weight: $ft-w-normal;
    margin: 35px 0;
    text-align: center;
  }

  &__ButtonGridContainer {
    display: flex;
    width: 100%;
  }

  &__ButtonGrid {
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    &--justifyDesktopLeft {
      justify-content: left;
    }
  }

  &__ButtonContainer {
    margin-bottom: 20px;
    margin-top: 10px;
    max-width: 150px;
    min-height: 150px;
    width: 45%;

    a {
      display: inline-block;
      height: 100%;
      width: 100%;
    }

    .c-CardWrapper {
      border-radius: 30px;
    }
  }

  &--reducedIcon {
    .c-ButtonCard__icon {
      width: 30px;
    }
  }

  &__MoreButton {
    color: $tertiary-text-color;
    cursor: pointer;
    margin: 2.5%;
  }

  &__link {
    color: $color-primary;
    display: flex;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 20px auto 0;
    text-decoration: underline;
    width: fit-content;
  }

  &__buttonCardLink {
    &--disabled {
      pointer-events: none;
    }
  }

  &--reducedTextOnMobile {
    @media only screen and (max-width: 900px) {
      .c-ButtonCard__main-text {
        font-size: $secondary-font-size;
      }
    }
  }
}

.c-WalletLandingPage:after {
  background: $off-white;
  height: 80%;
}

@media only screen and (max-width: 600px) {
  .c-WalletLandingPage {
    background: transparent;
    position: relative;

    &__ButtonContainer {
      min-width: 0;

      .c-ButtonCard {
        width: auto;
      }
    }
  }

  .c-WalletLandingPage:after {
    background: $darkBackground;
    content: "";
    height: 150px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

@media only screen and (min-width: 900px) {
  .c-WalletLandingPage {
    &__ButtonGridContainer {
      display: flex;
      margin: auto;
      width: 900px;
    }

    &__ButtonContainer {
      max-width: 250px;
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 500px) {
  .c-WalletLandingPage {
    &__ButtonGrid {
      justify-content: space-around;
    }

    &__ButtonContainer {
      margin: 10px 5% 20px;
    }

    &__pageTitle {
      margin: auto;
      width: 900px;
    }
  }
}
