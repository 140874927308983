@import '../../styles/main';

.c-DeviceLookup {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 400px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 400px;
    }
  }

  &__mobileTitle {
    -webkit-font-smoothing: antialiased;
    margin-bottom: 30px;
  }

  &__form {
    margin-top: 60px;
  }
}
