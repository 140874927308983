@import '../.././../styles/all';

@mixin text {
  color: $tertiary-text-color;
  font-family: $font-stack;
  padding: 0 16px;
}

.c-InputRadioButton {
  color: $tertiary-text-color;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  outline: none;
  position: relative;
  width: 100%;

  input[type=radio] {
    margin-right: 15px;
    visibility: hidden;
  }

  input[type=radio]:before {
    background: white;
    border: solid 1.5px $color-primary;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    visibility: visible;
    width: 16px;
  }

  input[type=radio]:checked:after {
    background: $color-primary;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 9px;
    left: 5px;
    position: absolute;
    top: 5px;
    visibility: visible;
    width: 9px;
  }

  &--error {
    &[type='radio'] {
      color: $color-error;
    }
  }
}
