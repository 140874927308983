@import '../../../../styles/main';

.c-CoverTypeDependantFields {
  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }
}
