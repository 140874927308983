@import '../../styles/main';

.c-LoginPage {
  background-color: $dark;
  height: 100%;

  &__container {
    background: $darkBackground;
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }

  &__createAccountButton {
    background: transparent;
    border: solid 3px $white;
    font-size: $primary-font-size;
    font-style: normal;
    font-weight: bold;
    line-height: 1.19;
    margin-top: 40px;
    text-align: center;
    width: 100%;
  }

  &__mobileTitle {
    margin-bottom: 30px;
  }

  &__customLoginLabel {
    color: $white;
    font-size: $primary-font-size;
    font-weight: $ft-w-500;
    line-height: 1.31;
  }

  &__button {
    display: flex;
    font-size: $primary-font-size;
    margin-top: 40px;
  }

  &__link {
    color: $white;
    display: flex;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__link_less_padding {
    margin: 2.5% auto 0;
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }

  &__link {
    color: $white;
    display: flex;
    font-size: $secondary-font-size;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-500;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 5% auto 0;
    text-decoration: underline;
    width: fit-content;

    @include tablet-and-above {
      margin: 30px auto 0;
    }
  }

  &__resendEmailMessageContainer {
    color: $white;
    margin-top: 10px;
  }

  #createAccountButton {
    background: transparent;
  }

  #LoginPage__loginButton {
    font-size: $primary-font-size;
  }
}
