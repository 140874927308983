@import "../../styles/all";

// Main button style
$button-color: $color-primary;
$button-text-color: $white;
$button-height: 60px;
// Secondary Button Style
$secondary-button-color: $background-color-tertiary;
$secondary-button-text-color: $color-primary;
$secondary-button-border-color: $color-primary;
// Tertiary Button Style
$tertiary-button-color: $btn-tertiary-bg-color;
$tertiary-button-text-color: $tertiary-text-color;
$tertiary-button-border-color: $btn-tertiary-border-color;

// Rounded button style
$rounded-button-color: $orange;

.c-Button {
  @include fontBase($primary-size, $font-stack, $button-text-color);

  background-color: $button-color;
  border: 1px solid transparent;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 600;
  max-width: 320px;
  min-height: $button-height;
  min-width: 80px;
  outline: none;
  transition: background-color 0.2s ease-in-out;
  user-select: none;

  &--fluid {
    max-width: 100%;
    width: 100%;
  }

  &:hover {
    background-color: darken($button-color, 5%);
  }

  &:active {
    background-color: darken($button-color, 6%);
    transition: background-color 0s ease-in-out;
  }

  &--disabled {
    opacity: 0.5;
    user-select: none;

    &:hover {
      cursor: not-allowed;
    }

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: $button-color;
    }
  }

  &--orange {
    background-color: $orange;

    &:hover {
      background-color: darken($orange, 5%);
    }

    &:active {
      background-color: darken($orange, 7%);
      transition: background-color 0s ease-in-out;
    }
  }

  &--rounded {
    border-radius: 100px;
  }

  &--orange-inverted {
    background-color: white;
    color: $orange;

    &:hover {
      $dark-orange: darken($orange, 5%);

      background-color: white;
      color: $dark-orange;
    }

    &:active {
      $dark-orange: darken($orange, 7%);

      background-color: white;
      color: $dark-orange;
      transition: color 0s ease-in-out;
    }
  }

  &--secondary {
    background-color: $secondary-button-color;
    border-color: $secondary-button-border-color;
    border-width: 3px;
    color: $secondary-button-text-color;

    &:hover {
      background-color: darken($secondary-button-color, 5%);
    }

    &:active {
      background-color: darken($secondary-button-color, 7%);
      transition: background-color 0s ease-in-out;
    }
  }

  &--disabled.c-Button--secondary {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: $custom-white;
      border: solid 3px $custom-white;
      color: $white;
    }
  }

  &--tertiary {
    background-color: $secondary-button-color;
    border-color: $secondary-button-border-color;
    color: $secondary-button-text-color;

    &:hover {
      background-color: darken($secondary-button-color, 5%);
    }

    &:active {
      background-color: darken($secondary-button-color, 7%);
      transition: background-color 0s ease-in-out;
    }
  }

  &--disabled.c-Button--tertiary {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: none;
      color: $custom-white;
    }
  }

  &--quaternary {
    background-color: $button-color;
    color: $button-text-color;

    &:hover {
      background-color: darken($button-color, 5%);
    }

    &:active {
      background-color: darken($button-color, 6%);
      transition: background-color 0s ease-in-out;
    }
  }

  &--disabled.c-Button--quaternary {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: $custom-white;
      border: solid 1px $custom-white;
      color: $white;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
