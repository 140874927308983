@import '../../styles/all';
@import '../Inputs/Common/CommonStyles.scss';

.c-AutoAddressAutocomplete {
  position: relative;

  ul {
    background: white;
    border: thin solid $silver;
    list-style: none;
    margin: 0;
    max-height: 150px;
    overflow: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 100%;
    width: 100%;
    z-index: 10;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $silver;
      border-radius: 10px;
    }

    li {
      color: $tertiary-text-color;
      padding: 15px;
    }
  }
}
