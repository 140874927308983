
@import "../../styles/main";

$desktop-padding-top: 70px;

.c-HelpPage {
  padding: 7.5%;

  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }

    @include tablet-and-above {
      margin: auto;
      padding-bottom: 10px;
      width: 100%;
    }
  }

  &__phoneButtonContainer {
    margin: auto;
    width: 100%;

    @include tablet-and-above {
      margin: auto;
      width: 400px;
    }
  }

  &__customContent {
    margin-bottom: 20px;
    margin-top: 20px;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
    }
  }

  &__customContentText {
    margin-left: 32px;
    text-align: left;

    @include tablet-and-above {
      margin: auto;
      padding: 10px 42px;
      text-align: left;
    }
  }

  &__visitSiteContent {
    margin-bottom: 20px;
    margin-top: 20px;

    @include tablet-and-above {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--spacing {
    margin-bottom: 30px;
  }

  &__title {
    color: $tertiary-text-color;
    font-size: $h3-font-size;
    padding: 20px;
    text-align: center;
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
    }
  }

  &__button {
    display: flex;
    margin-bottom: 86px;
  }

  &__accordions {
    font-family: $font-stack;
    margin: auto;
    width: 100%;
  }

  &__buttonContainer {
    margin: auto;
    padding-bottom: 10%;
    width: 90%;

    @include tablet-and-above {
      padding-bottom: 10%;
    }
  }

  &__customAncher {
    appearance: button;
    background-color: $deep-purple;
    border: 1px solid transparent;
    box-shadow: none;
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    font-size: $secondary-font-size;
    font-stretch: normal;
    font-weight: $ft-w-bold;
    letter-spacing: normal;
    line-height: 60px;
    min-height: 60px;
    min-width: 100%;
    outline: none;
    transition: background-color 0.2s ease-in-out;
  }
}
