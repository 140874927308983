@import '../../styles/main';

.c-Navigation {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  padding-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity ease-in-out 0.2s 0.2s, width ease-in-out 0.2s 0.5s;
  width: 0;
  z-index: 1000;

  &--open {
    opacity: 1;
    transition: opacity ease-in-out 0.2s, width ease-in-out 0s 0s;
    width: 100%;
  }

  &__overlay {
    background: rgba(49, 49, 49, 0.7);
    height: 100%;
    padding-left: 60px;
    transition: opacity ease-in-out 0.2s;
    width: 100%;
  }

  &__content {
    background: $darkBackground;
    display: flex;
    flex-direction: column;
    float: right;
    height: 100%;
    padding: 0 0 10px;
    transition: width ease-in-out 0.4s;
    width: 0;

    &--open {
      transition: width ease-in-out 0.4s 0.2s;
      width: 100%;

      @include tablet-and-above {
        width: 30%;
      }
    }
  }

  &__contentGroup {
    flex: 1;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
  }

  &__companyLogo {
    height: 33.9px;
    margin-bottom: 30px;
    margin-left: 30px;
    object-fit: contain;
    width: 104.3px;
  }

  &--with-padding {
    padding: 15px 30px;
  }

  &--blue {
    color: $macAndCheese;
    font-weight: 100;
  }
}
