@import '../../styles/main';

.c-Messages {
  .c-DesktopCard {
    margin: 30px;
  }

  &__messageContainer {
    border-top: thin solid $silver;

    @include desktop-and-above {
      &:first-of-type {
        border-top: none;
      }
    }
  }

  &__title {
    display: block;
    text-align: left;

    @include mobile-only {
      padding: 0 27px;
    }
  }
}
