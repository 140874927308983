@import '../../styles/main';

.c-QuoteDisplay {
  background-color: $white;
  display: inline-flex;
  margin-top: 10px;
  padding: 40px 20px;
  width: 100%;

  &__container {
    display: flex;
    margin: auto;
    width: 600px;
  }

  @include tablet-and-above {
    padding: 40px 10%;

    &__container {
      display: flex;
      margin: auto;
      width: 600px;
    }
  }

  &__priceContainer {
    flex: 1;
    margin: auto;
  }

  &__price {
    align-items: center;
    display: inline-flex;
  }

  &__priceCurrencyIcon {
    color: $tertiary-text-color;
    font-size: 16px;
    font-weight: 600;
    margin: 0 4px;
  }

  &__priceNumber {
    color: $tertiary-text-color;
    font-size: 28px;
    font-weight: 600;
  }

  &__buttonContainer {
    flex: 1;
    margin: auto;
    max-width: 150px;
    width: 150px;
  }

  &__buttonContainerEdit {
    flex: 1;
    margin: auto;
    margin-right: 20px;
    max-width: 150px;
    width: 150px;
  }

  &__button {
    width: 100%;
  }

  &--sticky {
    bottom: 0;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.15);
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10;
  }

  &__monthlyPaymentTitle {
    display: inline;
    font-size: 14px;
    vertical-align: top;
  }
}
