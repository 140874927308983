@import '../../styles/main';

.c-AddAdditionalDriver {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }
}
