@import '@arachas/core/lib/styles/_all.scss';

.c-NavigationGroup {
  background: none;
  list-style: none;
  margin-bottom: 10px;
  margin-top: 0;
  min-width: 300px;
  padding: 0;

  &__item {
    background: rgba(255, 255, 255, 0.2);
    color: $white;
    display: block;
    font-weight: normal;
    height: 50px;
    line-height: 1.25;
    margin-bottom: 2px;
    padding: 15px 30px;
  }
}
