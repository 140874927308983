@import '../../../styles/main';

$contact-margin-left: 4%;
$contact-margin-top: $contact-margin-left * 2;

.c-YourCarCoverForm {
  &__container {
    padding: $form-vertical-padding 0;

    @include tablet-and-above {
      margin: 0 auto;
      padding: 50px 0;
      width: $form-container-width;
    }

    @include desktop-and-above {
      width: $form-container-width;
    }

    .c-AccordionText__text {
      font-weight: bold;
    }
  }

  &__componentContainer {
    padding: 0 $form-field-padding;
  }

  &__additionalDriversContainer {
    @include dynamicFieldsContainer;

    margin-top: $medium-margin;
    padding: $form-field-padding;
  }

  &__contactByContainer {
    margin: $contact-margin-left 0 0 $contact-margin-top;
  }

  &__checkboxContainer {
    margin-bottom: 15px;
  }

  &__motorClaimsLabelContainer {
    padding: 0 $form-field-padding;
  }

  &__penaltyPointsMainContainer {
    padding: 0 $form-field-padding;
  }

  &__penaltyPointsRepeatingContainer {
    padding: $form-field-padding;
  }

  &__termsAndConditionsContainer--padding {
    padding: 0 24px;
  }

  &__infoText {
    font-size: 11px;
  }
}
