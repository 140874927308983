@import "../../styles/main";

.c-MobileBackButton {
  &__arrowLeft {
    font-size: $h4-font-size;
  }

  &__button {
    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    padding: 0;
  }

  &__text {
    color: $color-primary;
    font-family: $font-stack;
    font-size: $h4-font-size;
    font-stretch: normal;
    font-style: normal;
    font-weight: $ft-w-bold;
    letter-spacing: normal;
    line-height: 1.33em;
    margin-left: 7px;
    text-align: left;
  }
}
