////
/// @group _s-settings
////
/// SETTINGS
/// - borders
/// - animation
/// - sizing
/// - breakpoints

// BORDERS
/// Border
/// - [4px]
/// @type Border
$border-radius: 4px;

// ANIMATION - WIP
// Fasest:   .15s
// Fast:     .4s
// Normal:   .9s
$animate-normal-duration: 0.9s;
// Slow:    1.6s
// Slowest: 2s

/// Animation Speed fast-ease
/// - [all 0.4s ease]
/// @type Speed
$animate-fast-ease: all 0.4s ease;

/// Animation Speed animate-normal-ease
/// - [all 0.9s ease]
/// @type Speed
$animate-normal-ease: all $animate-normal-duration ease;

/// Animation Speed animate-normal-fade
/// - [all 0.9s ease]
/// @type Speed
$animate-normal-fade: $animate-normal-ease;

/// Animation Speed fastest-inout
/// - [all 0.2s ease-in-out]
/// @type Speed
$animate-fastest-inout: all 0.2s ease-in-out;

///
/// @group _s-settings
///
$tablet-width: 600px;

///
/// @group _s-settings
///
$tablet-container-width: 600px;

///
/// @group _s-settings
///
$desktop-width: 1024px;

///
/// @group _s-settings
///
$desktop-container-width: 930px;

///
/// @group _s-settings
///
$desktop-form-width: 600px;

///
/// @group _s-settings
///
$input-field-height: 56px;
