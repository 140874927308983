@import '../../styles/main';

.c-AddRenewalDate {
  @include standardPageLayout;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    font-size: $h3-font-size;
    padding-bottom: 8px;
  }

  &__subTitle {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
  }

  &__fieldLabel {
    color: $tertiary-text-color;
    font-family: $font-stack;
    line-height: 22px;
    padding-bottom: 5%;
    text-align: left;
  }

  &__error {
    color: $light-red;
    text-align: center;
  }

  @include mobile-only {
    &__container {
      margin-top: 30px;
    }

    &__title,
    &__subTitle {
      padding-bottom: 20px;
    }
  }
}
