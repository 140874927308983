/// - [#000] - $black - It's black?
/// @type Color
$black: #000;

/// - [#fff] - $white - It's white?.
/// @type Color
$white: #fff;

/// - [#f00] - $red - It's red?.
/// @type Color
$red: #f00;

/// - [#ed8b00] - &orange = It's orange?.
/// @type Color
$orange: #ed8b00;

/// - [#ef6c00] - $dark-orange - It's silver!
/// @type Color
$dark-orange: #ef6c00;

/// - [#f9fafa] - $background-color-tertiary - Lightest background color for small prominence.
/// @type Color
$background-color-tertiary: #f9fafa;

/// - [#f4f5f6] - $background-color-secondary - Used for background greys that need more prominence.
/// @type Color
$background-color-secondary: #f4f5f6;

/// - [#eaebed] - $background-color-primary - Darkest background colour.
/// @type Color
$background-color-primary: #eaebed;

/// - [#d7d5d5] - $color-stroke - For for around tables and dividing lines.
/// @type Color
$color-stroke: #d7d5d5;

/// - [#a0a1a3] - $color-inputs - For use on input borders (including radio and checkboxes).
/// @type Color
$color-inputs: #a0a1a3;

/// - [#979fa5] - $color-icons - Grey for icons.
$color-icons: #969ea4;
/// @type Color

/// monochrome
/// - [#6e7881] - $font-color-secondary - Secondary text of lower importance to the primary content.
/// @type Color
$font-color-secondary: #6e7881;

/// monochrome

/// - [#26cad3] - $color-secondary - Complimentary / Secondary to BOI Blue
/// @type Color
$color-secondary: #26cad3;

/// - [#600460] - $color-primary - The main, the king Arachas Purple. Used to highlight specific areas.
/// @type Color
$color-primary: #600460; //same as primary-purple

/// green
/// - [#81bc29] - $color-positive - Used as primary BOI green
/// @type Color
$color-positive: #81bc29;

/// green
/// - [#6db841] - $green - Used BOI green
/// @type Color
$green: #6db841;

/// - [#ccc] - $silver - It's silver!
/// @type Color
$silver: #ccc;

/// yellow
/// - [#e98300] - $color-hazard - WARNING It's for warnings.
/// @type Color
$color-hazard: #e98300;

/// red
/// - [#da333e] - $color-error - Used as errors and negative elements
/// @type Color
$color-error: #da333e;

/// Shadows
/// - [#0 0 8px 0 rgba(0, 0, 0, 0.02)] - Needs docs.
/// @type Variable
$black-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02);

// Move settings below to their specific files
$inverted-font-color: $white;

// Button
$btn-text-color: $color-primary;
$btn-text-focus-color: $color-icons;
$btn-bg-focus-color: $background-color-tertiary;

//Button tertiary
$btn-tertiary-bg-color: #fff;
$btn-tertiary-border-color: $color-inputs;
$tertiary-text-color: #474747;

// NavBar
$nav-text-color: $color-primary;
$nav-text-focus-color: $color-icons;
$nav-bg-focus-color: $background-color-tertiary;
$nav-divider-color: $color-stroke;

// ProgressBar
$prog-value-color: $color-positive;
$prog-bg-color: rgba(255, 255, 255, 0.7);

/// - [#053c59] - $dark-blue.
/// @type Color
$dark-blue: #053c59;

/// - [#fafbfc] - $off-white.
/// @type Color
$off-white: #fafbfc;

/// - [#1081a6] - $blue-notification - It's silver!
/// @type Color
$blue-notification: #1081a6;

/// - [#106988] - $primary-blue. //same as $color-primary @amanda todo update to be one color
/// @type Color
$primary-blue: #106988;

/// - [#0000FF] - $deep-blue. //deep deep blue
/// @type Color
$deep-blue: #00f;

/// - [#999] - $medium-gray. //medium gray
/// @type Color
$medium-gray: #999;

/// - [#000] - $black. //black
/// @type Color
$black: #000;

/// - [#d3d3d3] - $white-gray. //white gray
/// @type Color
$white-gray: #d3d3d3;

/// - [#616365] - $light-gray - It's light-gray!
/// @type Color
$light-gray: #616365;

/// - [#eee] - $beige - It's light-gray!
/// @type Color
$beige: #eee;

/// - [##4caf50] - $bright-green - It's bright-green!
/// @type Color
$bright-green: #4caf50;

/// - [#c0c1c3] - $divider-color - It's divider-color!
/// @type Color
$divider-color: #c0c1c3;

/// - [#f0f0f0] - $gray-94 - It's gray-94!
/// @type Color
$gray-94: #f0f0f0;

/// - [#fafafa] - $gray-98 - It's gray-98!
/// @type Color
$gray-98: #fafafa;

/// - [#2e3d49] - $nile-blue - It's nile-blue!
/// @type Color
$nile-blue: #2e3d49;

/// - [#600060] - $rich-purple - It's rich-purple!
/// @type Color
$rich-purple: $color-primary;

/// - [#474747] - $greyish-brown - It's greyish-brown!
/// @type Color
$greyish-brown: $tertiary-text-color;

/// - [#868686] - $grey-light - It's grey-light!
/// @type Color
$grey-light: #868686;

/// @type Color
$custom-white: #e5e5e5;

/// @type Color
$soft-red: #e02222;
