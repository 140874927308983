@import '../../styles/main';

.c-ChangePassword {
  @include standardPageLayout;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    color: $tertiary-text-color;
    font-size: 24px;
    padding-top: 12px;
  }

  &__fieldLabel {
    color: $tertiary-text-color;
    font-family: $font-stack;
    line-height: 22px;
    margin: 10px 5px;
    padding-bottom: 15px;
    text-align: left;
  }
}
