@import 'main';

.g-CommonFormStyles {
  @include tablet-and-above {
    margin: 0 auto;
    padding: 50px 0;
    width: $form-container-width;
  }

  &__fieldLabel {
    @include fieldLabel;

    display: block;
    margin-bottom: 11px;
    margin-right: 23px;
    text-align: left;
  }

  &__accordionTextFieldLabel {
    @include fieldLabel;

    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  &__fieldContainer {
    @include fieldContainer;
  }

  &__fieldContainerWithNoMargin {
    @include fieldContainer;

    margin: 0;
  }

  &__itemCountLabel {
    @include itemCountLabel;
  }

  &__itemCountContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: $medium-margin;
  }

  &__dividerContainer {
    margin-bottom: $medium-margin;
  }

  &--fieldLabelSpacing {
    margin: 4px 0;
  }

  &__infoText {
    @include secondaryTextFont;
  }

  &__nonDynamicFieldsContainer {
    padding: 0 $form-field-padding;
  }

  &__customNonDynamicFieldsContainer {
    margin-bottom: 20px;
    padding: 0 $form-field-padding;
  }

  &__dynamicFieldsContainer {
    @include dynamicFieldsContainer;

    margin-bottom: $medium-margin;
    padding: $form-field-padding;

    &--with-no-padding {
      padding: 0;
    }
  }

  &--withNoMargin {
    margin: 0;
  }

  &--withMarginTop {
    margin-top: 30px;
  }

  &__checkboxLink {
    font-size: $primary-font-size;
    margin-left: 3px;
  }

  &__checkboxContainer {
    margin: 10px 0 0 2px;
  }

  &__checkboxContainerList {
    margin: 10px 0 0 2px;

    .c-Checkbox__inputContainer {
      margin-top: 0;
    }
  }

  &__contactByCheckboxContainer {
    margin-bottom: 5px;
  }

  &__contactByContainer {
    margin-top: 15px;
    padding-left: 25px;
  }

  &__subFieldLabel {
    @include fieldLabel;

    margin: 5px 0;
    text-align: left;
    width: 100%;
  }

  &__dashedBrokenLine {
    border-bottom: 1px dashed $grey-light;
  }
}
