@import '../.././../styles/all';
@import '../Common/CommonStyles.scss';

// Base64 encoded arrow svgs
$dropdown-svg: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC4zODkiIGhlaWdodD0iOS4yMzciIHZpZXdCb3g9IjAgMCAxNC4zODkgOS4yMzciPgogIDxwYXRoIGlkPSJfIiBkYXRhLW5hbWU9Iu+BuCIgZD0iTTE0LjIyMSwyLjQ1Myw3LjYsOS4wNjlhLjU2Ni41NjYsMCwwLDEtLjgsMEwuMTY3LDIuNDUzYS41NzcuNTc3LDAsMCwxLDAtLjgxM0wxLjY1LjE2N2EuNTY2LjU2NiwwLDAsMSwuOCwwTDcuMTk0LDQuOTA4LDExLjkzNS4xNjdhLjU2Ni41NjYsMCwwLDEsLjgsMGwxLjQ4MiwxLjQ3M0EuNTc3LjU3NywwLDAsMSwxNC4yMjEsMi40NTNaIiBmaWxsPSIjYTBhMWEzIi8+Cjwvc3ZnPgo=');
$dropdown-svg-reversed: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC4zODkiIGhlaWdodD0iOS4yMzciIHZpZXdCb3g9IjAgMCAxNC4zODkgOS4yMzciIHRyYW5zZm9ybT0icm90YXRlKDE4MCkiPgogIDxwYXRoIGlkPSJfIiBkYXRhLW5hbWU9Iu+BuCIgZD0iTTE0LjIyMSwyLjQ1Myw3LjYsOS4wNjlhLjU2Ni41NjYsMCwwLDEtLjgsMEwuMTY3LDIuNDUzYS41NzcuNTc3LDAsMCwxLDAtLjgxM0wxLjY1LjE2N2EuNTY2LjU2NiwwLDAsMSwuOCwwTDcuMTk0LDQuOTA4LDExLjkzNS4xNjdhLjU2Ni41NjYsMCwwLDEsLjgsMGwxLjQ4MiwxLjQ3M0EuNTc3LjU3NywwLDAsMSwxNC4yMjEsMi40NTNaIiBmaWxsPSIjYTBhMWEzIi8+Cjwvc3ZnPgoK');

.c-Dropdown {
  @include e-InputFieldStates;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: $dropdown-svg;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 12px;
}
