@import '../../styles/all';

$link-color: $color-primary;
$slider-color: $white;
$slider-border-color: $black;

.c-StarRating {
  &__container {
    width: 100%;
  }

  &__starsRow {
    display: flex;
    flex-direction: row;
    font-size: 19.5px;
  }

  &__individualStar {
    align-items: center;
    color: $link-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    outline: none; /* Remove outline */

    &:hover {
      background-color: $white;
      opacity: 1;
    }
  }

  &__buttonIcon {
    margin: auto;
    width: 25px;
  }
}
