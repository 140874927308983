@import '../../styles/main';

.c-MotorChangeCar {
  @include standardPageLayout;

  @include desktop-and-above {
    max-width: $desktop-form-width;
    width: 95%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    @include tablet-and-above {
      @include loginJourneyPagePadding;
    }
  }

  &__innerContent {
    @include tablet-and-above {
      height: 500px;
      margin: auto;
      padding: 0;
      width: 600px;
    }
  }

  &__button {
    display: flex;
    margin-bottom: 86px;
    margin-top: 40px;
  }

  &__form {
    @include tablet-and-above {
      padding-top: 30px;
    }

    padding-top: 30px;
  }

  &__subHeading {
    font-size: 14px;
    padding-top: 10px;
  }

  &__content {
    font-size: 14px;
    padding-bottom: 30px;
  }
}
