@import "../../styles/main";

.c-IconAndTitle {
  &__iconContainer {
    display: flex;
    flex-direction: column;

    @include mobile-only {
      align-items: center;
    }

    @include tablet-and-above {
      width: 100%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    margin-bottom: 20px;
    width: $icon-width-mobile;

    &--van {
      width: $van-icon-mobile-width;
    }

    &--home {
      width: $home-icon-mobile-width;
    }

    &--device {
      width: $device-icon-mobile-width;
    }

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;

      &--van {
        width: $van-icon-desktop-width;
      }

      &--home {
        width: $home-icon-desktop-width;
      }

      &--device {
        width: $device-icon-desktop-width;
      }
    }
  }

  &__customTitle {
    @include tablet-and-above {
      margin-top: 20px;
    }
  }

  &__titleText {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    text-align: center;

    @include mobile-only {
      color: $tertiary-text-color;
      margin-bottom: 20px;
    }
  }
}
