@import '../../styles/main';
@import '../../styles/commonPageStyles';

.c-PaymentsPage {
  @include pageWithQuoteDisplayLayout;

  @include tablet-and-above {
    margin: 0 15% 64px;
  }

  @include desktop-and-above {
    margin: 0 auto 64px;
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
    width: 1100px;
  }

  &__buttonGroupLabelMargin {
    margin-bottom: 5px;
  }

  &__paddedContainer {
    padding: 0 $medium-padding;
  }

  &__premiumBreakdownContainer {
    margin-bottom: $medium-margin;
  }

  &__card {
    @include noPaddingCardContainer;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    padding: $medium-padding 0;

    @include tablet-and-above {
      padding: 40px 0 $medium-padding;
    }
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: 0 auto;
      width: 400px;
    }
  }

  &__input {
    @include tablet-and-above {
      width: 74%;
    }
  }
}
