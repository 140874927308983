@import '../../styles/main';

.c-AdditionalDriverDetails {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;

  > * {
    padding: 30px 15px;
  }

  &__title {
    border-bottom: 3px solid $color-primary-dark;
    padding-bottom: 15px;
  }
}
