@import "../../styles/all";

.c-ContactButton {
  &__button {
    @include fontBase($quinary-size, $font-stack, $white);

    align-items: center;
    background-color: $color-primary;
    border: none;
    cursor: pointer;
    display: flex;
    font-weight: $ft-w-bold;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &--whiteBackground {
      background-color: rgba(96, 4, 96, 0.05);
      border: thin solid $silver;

      @include fontBase($quinary-size, $font-stack, $tertiary-text-color);
    }
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__text {
    font-size: 14px;
    font-style: $ft-w-normal;
    font-weight: $ft-w-bold;
    margin-left: 23px;
    text-align: left;
  }

  &__contactLabel {
    font-size: 12px;
    font-weight: $ft-w-normal;
    margin-left: 23px;
    text-align: left;
  }

  &__suffix {
    align-items: center;
    background-color: $color-primary;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    font-size: $h3-size;
    height: 66px;
    justify-content: center;
    margin-left: 20px;
    width: 50px;

    &--whiteBackground {
      background: transparent;
      color: $color-primary;
    }
  }

  @media only screen and (min-width: 900px) {
    &__button {
      @include fontBase($quinary-size, $font-stack, $white);

      &--whiteBackground {
        background-color: rgba(96, 4, 96, 0.05);
        border: thin solid $silver;
        margin: auto;
        width: 100%;

        @include fontBase($quinary-size, $font-stack, $tertiary-text-color);
      }
    }
  }
}
