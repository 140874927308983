@import '../../../../styles/main';

.c-TravelInsuredPerson {
  &__repeatingFieldsContainer {
    @include dynamicFieldsContainer;

    margin-bottom: $medium-margin;
    padding: $form-field-padding;
  }

  &__lastRepeatingField {
    margin-bottom: 0;
  }

  &__formPaddingContainer {
    padding: 0 $form-field-padding;
  }

  &__numChildrenTextSeparator {
    line-height: 30px;
    margin: 0 10px;
  }
}
