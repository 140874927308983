@import '../../styles/main';

.c-QuoteExtras {
  &__readMore {
    color: $color-primary;
  }

  &__readMoreContent {
    color: $tertiary-text-color;
    font-size: $secondary-font-size;
    padding: 10px 0;
    text-decoration: none;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
