@import "../../styles/main";

.c-MotorViewQuote {
  @include pageWithQuoteDisplayLayout;

  &__logo {
    margin-bottom: 30px;
  }

  &__desktopTitle {
    justify-content: center;
    text-align: center;
  }

  &__underwrittenText {
    align-items: center;
    color: $tertiary-text-color;
    display: flex;
    font-size: $secondary-font-size;
    margin-bottom: 30px;

    img {
      padding-left: 10px;
    }
  }

  &__allianzLogo {
    height: 20px;
  }

  &__avivaLogo {
    height: 40px;
  }

  &__zurichLogo {
    height: 40px;
  }

  &__axaLogo {
    height: 48px;
  }

  &__subHeading {
    color: $tertiary-text-color;
    font-size: $primary-font-size;
    margin-bottom: 25px;
  }

  &__card {
    @include noPaddingCardContainer;

    padding: $form-field-padding 0;
  }

  &__paddedContainer {
    padding: 0 $form-field-padding;
  }

  &__quoteInfoContainer {
    margin-bottom: $medium-margin;
  }

  &__readMoreContainer {
    color: $primary-color;
  }

  &__readMoreContent {
    color: $tertiary-text-color;
    font-size: 10px;
    padding-bottom: 10px;
    text-decoration: none;
  }

  &__readMoreTitle {
    font-weight: $ft-w-bold;
    margin-top: 15px;
  }

  &__contentContainer {
    @include tablet-and-above {
      margin: auto;
      width: 100%;
    }
  }

  &__title {
    padding-bottom: 15px;
    width: 100%;
  }

  &__excessContainer {
    margin: 20px 10px;
  }

  &__consentSection {
    margin: 10px 0;
  }

  &__buySection {
    display: inline-flex;
    margin-top: 10px;
    width: 100%;
  }

  &__quotePriceContainer {
    margin: auto;
  }

  &__quotePrice {
    color: $color-primary;
    display: inline-flex;
  }

  &__buyButtonContainer {
    margin: auto;
  }

  &--excessValueLabel {
    margin-bottom: 10px;
  }

  &__premiumBreakdown {
    padding-top: 5%;
  }

  &__bulletPoints {
    margin-block-start: 0;
    padding-inline-start: 35px;
  }

  &__switchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $small-margin;
  }

  &__beforeText {
    color: $black;
    margin-top: 15px;
    padding: 13px 13px 0;
    text-align: center;
  }

  .c-AccordionCard__container {
    border-width: 0;
  }
}
