@import '../../../styles/all';

$spinner-height-width: 80px;

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(720deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(720deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

@mixin iconCircle2 {
  border: 4px solid $rich-purple;
  border-radius: 50%;
  box-sizing: content-box;
  height: $spinner-height-width;
  left: -4px;
  position: absolute;
  top: -4px;
  width: $spinner-height-width;
  z-index: 0;
}

@mixin loadingIcon2MainCss {
  border: 4px solid $rich-purple;
  border-radius: 50%;
  box-sizing: content-box;
  height: $spinner-height-width;
  position: relative;
  width: $spinner-height-width;
}

@mixin loadingIcon2BeforeAndAfterPsuedo {
  content: '';
  height: 75px;
  position: absolute;
  transform: rotate(90deg);

  @include tablet-and-above {
    background: white;
  }
}

@mixin loadingIcon2AfterPsuedo {
  animation: spin 2s linear 0s infinite;
  border-radius: 0 100px 100px 0;
  left: calc(#{$spinner-height-width} / 2);
  top: 0;
  transform-origin: 0 50%;
  width: 60px;
}

@mixin loadingIcon2 {
  @include loadingIcon2MainCss;

  &:after {
    @include loadingIcon2AfterPsuedo;
  }

  &:before,
  &:after {
    @include loadingIcon2BeforeAndAfterPsuedo;

    background: $off-white;
  }

  &__icon-circle2 {
    @include iconCircle2;
  }
}

.c-AnimatedSpinner {
  &__componentContainer {
    height: 115px;
    margin: 0 auto;
    width: 80px;
  }

  &__dots {
    fill: $off-white;
    height: 88px;
    position: absolute;
    width: 88px;
    z-index: 1;

    @include tablet-and-above {
      fill: white;
    }
  }

  &__success-checkmark2 {
    height: 115px;
    margin: 0 auto;
    position: relative;
    width: 80px;

    svg {
      left: 0;
    }

    &__loading-icon2 {
      @include loadingIcon2;

      &:before,
      &:after {
        background: $off-white;
      }
    }

    &__loading-white-icon2 {
      @include loadingIcon2;

      &:before,
      &:after {
        background: $white;
      }
    }
  }
}
