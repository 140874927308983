@import '../../../../styles/main';

.c-GoCardlessLegal {
  color: $tertiary-text-color;

  &__checkbox {
    margin-top: 20px;

    .c-Checkbox {
      color: $tertiary-text-color;
    }
  }

  &--margin {
    margin-top: 30px;
  }

  &__title {
    font-size: $h3-font-size;
    font-weight: $ft-w-500;
  }

  ///////////////
  // TODO Exclude to match design
  &__link {
    color: $primary-color;
  }

  &__legalNotice {
    margin-top: 40px;
  }
  ////////////
}
