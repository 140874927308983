@import '../../styles/all';

.c-MobileCard {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  margin: 20px auto;
  padding: 20px 30px;
  width: $tablet-width;

  @include mobile-only {
    padding: 15px;
    width: 100%;
  }
}
