@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');

$font-stack: 'Montserrat', 'Roboto', Tahoma, Helvetica, Arial, sans-serif;

$h3-font-size: 24px;
$h4-font-size: 18px;

$icon-height-desktop: 100%;
$icon-width-desktop: 91px;

$icon-width-mobile: 50px;
$icon-height-mobile: 50px;

$device-icon-mobile-width: 45px;
$device-icon-desktop-width: 70px;
$van-icon-mobile-width: 70px;
$van-icon-desktop-width: 111px;
$home-icon-mobile-width: 70px;
$home-icon-desktop-width: 111px;

$primary-font-size: 16px;
$secondary-font-size: 14px;
$tertiary-font-size: 12px;

// Font Weights
$ft-w-light: 300;
$ft-w-normal: 400;
$ft-w-500: 500;
$ft-w-bold: 600;

@mixin fieldLabelFont {
  font-family: $font-stack;
  font-size: $primary-font-size;
  font-weight: $ft-w-500;
}

@mixin premiumBreakdownFont {
  font-family: $font-stack;
  font-size: $secondary-font-size;
}

@mixin primaryTextFont {
  font-family: $font-stack;
  font-size: $primary-font-size;
  font-stretch: normal;
  font-style: normal;
  font-weight: $ft-w-500;
  letter-spacing: normal;
  line-height: 1.38;
}

@mixin secondaryTextFont {
  font-family: $font-stack;
  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;
}

@mixin pageSecondaryTitleFont {
  font-family: $font-stack;
  font-size: $h3-font-size;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.38;
}
