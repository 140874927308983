@import '../../../styles/all';

$spinner-height-width: 80px;

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(720deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(720deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

.componentContainer {
  height: 115px;
  margin: 0 auto;
  width: 80px;
}

.dots {
  fill: white;
  height: 88px;
  position: absolute;
  width: 88px;
  z-index: 1;
}

.success-checkmark2 {
  height: 115px;
  margin: 0 auto;
  position: relative;
  width: 80px;

  svg {
    left: 0;
  }

  .loading-icon2 {
    border: 4px solid $bright-green;
    border-radius: 50%;
    box-sizing: content-box;
    height: $spinner-height-width;
    position: relative;
    width: $spinner-height-width;

    &:after {
      animation: spin 2s linear 0s infinite;
      border-radius: 0 100px 100px 0;
      left: calc(#{$spinner-height-width} / 2);
      top: 0;
      transform-origin: 0 50%;
      width: 60px;
    }

    &:before,
    &:after {
      background: white;
      content: '';
      height: 75px;
      position: absolute;
      transform: rotate(90deg);
    }

    .icon-circle2 {
      border: 4px solid rgba(76, 175, 80, 0.5);
      border-radius: 50%;
      box-sizing: content-box;
      height: $spinner-height-width;
      left: -4px;
      position: absolute;
      top: -4px;
      width: $spinner-height-width;
      z-index: 0;
    }
  }
}
