@import '@arachas/core/lib/styles/_all.scss';

.c-ThreeStateToggle {
  &__toggleContainer {
    align-items: center;
    display: flex;
    flex-direction: row;

    > * {
      flex: 1;
    }
  }

  &__container {
    border: thin solid #ccc;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    justify-content: space-between;
    max-width: 143px;
    padding: 2px;
    position: relative;
    width: 143px;

    > * {
      cursor: pointer;
      flex: 1;
      text-align: center;
    }
  }

  &__status {
    background: $rich-purple;
    border-radius: 18px;
    color: white;
    height: 36px;
    left: calc(50% - 20px);
    max-width: 36px;
    padding-top: 8px;
    position: absolute;
    right: calc(50% + 15px);
    top: 1px;
    width: 36px;
    z-index: -1;
  }

  &__statusYes {
    left: 2px;
    max-width: calc(50% + 14px);
    padding-right: 8px;
    padding-top: 8px;
    text-align: right;
    width: calc(50% + 15px);
  }

  &__statusNo {
    max-width: calc(50% + 14px);
    padding-left: 8px;
    padding-top: 8px;
    right: 2px;
    text-align: left;
    width: calc(50% + 15px);
  }

  &__statusSelected {
    color: white;
  }

  &__label {
    color: $tertiary-text-color;
    font-weight: $ft-w-bold;
    margin-bottom: 10px;
    padding-right: 15px;
  }

  &__statusLabel {
    font-weight: $ft-w-bold;
    max-width: calc(50% - 18px);
    padding: 6px 6px 0 0;
    width: calc(50% - 18px);
  }

  &__error {
    color: $color-error;
  }

  &__accordionText {
    color: $tertiary-text-color;
    font-weight: 600;
    padding-right: 15px;
  }

  .c-ThreeStateToggle__InfoText {
    font-weight: $ft-w-normal;
  }
}
