.c-DatePicker {
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    height: auto;
    max-width: 45px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  input[type='date']::-webkit-clear-button {
    z-index: 1;
  }
}
