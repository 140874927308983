@import '../../styles/main';

.c-DocumentsUploadPage {
  @include standardPageLayout;

  display: flex;
  flex-direction: column;
  font-size: $secondary-font-size;
  font-weight: $ft-w-normal;

  &__customTitleTextContainer {
    margin: auto;
    width: 600px;
  }

  &__customTitleText {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    margin-left: 5px;
    padding-top: 20px;

    @include mobile-only {
      display: none;
    }
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    text-align: center;
    width: $icon-width-mobile;

    > * {
      height: 100%;
    }

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__customTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    text-align: center;
  }

  &__subtitleText {
    margin: 10px 0 20px 20px;

    @include tablet-and-above {
      margin-left: 0;
    }
  }

  &__content {
    margin: auto;
    width: 100%;
  }

  &__closeIcon {
    float: right;
  }

  &__itemNumberContainer {
    display: flex;
    justify-content: space-between;
  }

  &__itemPromptAndNumberContainer {
    margin-bottom: 20px;
  }

  &__itemPromptContainer {
    margin-top: $small-margin;
  }

  &__button {
    @include tablet-and-above {
      margin: auto;
      width: 600px;
    }

    @include mobile-only {
      margin-top: 30px;
    }
  }
}
