@import '../../styles/main';

$desktop-padding-top: 70px;

.c-CallUsPage {
  @include standardPageLayout;

  &__container {
    @include tablet-and-above {
      margin: 0 auto;
      width: 400px;
    }
  }

  &__customTitleTextContainer {
    margin: auto;
    width: 600px;
  }

  &__customContent {
    margin-bottom: 20px;
    margin-left: 50px;
  }

  &__heading {
    margin: auto;
    padding-top: 20px;
    width: 80%;
  }

  &__customList {
    margin: auto;
    width: 85%;
  }

  &__phoneButtonContainer {
    margin: auto;
  }

  color: $tertiary-text-color;
  font-size: $secondary-font-size;
  font-weight: $ft-w-500;

  &__titleText {
    margin-top: 30px;
  }

  &__customTitle {
    color: $greyish-brown;
    display: block;
    font-size: $h1-size;
    font-weight: $ft-w-500;
    text-align: center;
  }

  &__content {
    margin-bottom: 30px;

    @include tablet-and-above {
      margin: 25px 0;
    }
  }

  &__customContent {
    margin-bottom: 20px;
    margin-left: 5px;
    margin-top: 20px;
  }

  &__afterContent {
    margin: 25px 0;
  }

  &__contentContainer {
    @include tablet-and-above {
      width: 100%;
    }
  }

  &__callText {
    color: $darkishGrey;
    font-size: $tertiary-font-size;
    font-weight: $ft-w-normal;
    letter-spacing: normal;
    line-height: 1.25;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;
    text-align: center;
    width: $icon-width-mobile;

    > * {
      height: 100%;
    }

    @include tablet-and-above {
      height: $icon-height-desktop;
      margin: auto;
      width: $icon-width-desktop;
    }
  }

  &__buttonContainer {
    margin: 10% 0 0;

    @include tablet-and-above {
      margin: 0;
      padding-top: 20px;
    }
  }

  &__container {
    margin-top: 5%;
  }

  .c-Button--secondary {
    background-color: transparent;
  }

  &__customTitleText {
    @include mobile-only {
      display: none;
    }

    padding-top: 20px;

    @include tablet-and-above {
      color: $greyish-brown;
      display: block;
      font-size: $h1-size;
      font-weight: $ft-w-500;
      margin-left: 5px;
      width: 30%;
    }
  }
}
