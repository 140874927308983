@import '../../../styles/all';

.c-Checkbox {
  @include fontBase(16px, $font-stack, $font-color-secondary);

  &__label {
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
  }

  &__checkbox {
    cursor: pointer;
    flex-basis: auto;
  }

  &__input {
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  &__inputContainer {
    cursor: pointer;
    display: flex;
    margin-top: 2px;
    position: relative;
  }

  &__text {
    margin-left: 11px;
    text-align: left;

    & .u-CheckboxLink {
      @include fontBase(16px, $font-stack, $color-primary);

      text-decoration: underline;
    }
  }
}
