@import '../../../../styles/main';

.c-PaymentDetails {
  &__price {
    color: $primary-color;
  }

  &--margin {
    font-style: italic;
    margin: 30px 0;
  }
}
