@import '../../styles/main';

.c-Loading {
  @include primaryTextFont;
  @include standardPageLayout;

  align-items: center;
  color: $tertiary-text-color;
  height: 100%;
  margin: 0 20%;
  text-align: center;

  &__icon {
    margin: auto;
  }

  .c-Title {
    padding: 0 0 25%;
  }

  &__buttonContainer {
    justify-content: center;

    @include tablet-and-above {
      margin: auto;
      width: 50%;
    }
  }
}
