@import '../../styles/main';

.c-MyProfile {
  @include standardPageLayout;

  @include tablet-and-above {
    width: 100%;

    @include customPageLayout;
  }

  &__buttonContainer {
    @include tablet-and-above {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 20px;
      width: 100%;
    }
  }

  &__buttonSpacing {
    margin-top: 3%;

    @include tablet-and-above {
      margin: auto;
      padding-top: 20px;
      width: 80%;
    }
  }

  &__iconContainer,
  &__titleContainer {
    display: flex;
    justify-content: center;
    margin: auto;

    @include tablet-and-above {
      width: 50%;
    }
  }

  &__icon {
    height: $icon-height-mobile;

    @include tablet-and-above {
      height: $icon-height-desktop;
    }
  }

  &__title {
    color: $tertiary-text-color;
  }
}
