@import "../../styles/main";

.c-ProgressBar {
  padding: $light-padding 0 $medium-padding 0;

  @include tablet-and-above {
    padding: 0 0 $medium-padding 0;
  }

  &__line {
    background-color: $mid-grey;
    height: 3px;
    opacity: 0.3;
    width: 100%;

    @include tablet-and-above {
      height: 4px;
      margin: 0 20px;
    }

    &--active {
      background-color: $color-primary;
      opacity: 1;
    }
  }

  &__icon {
    color: $mid-grey;
    opacity: 0.3;
    width: 50px;

    &--active {
      color: $color-primary;
      opacity: 1;
    }
  }

  &__label {
    color: $mid-grey;
    font-size: 13px;
    opacity: 0.3;
    text-align: center;
    width: 40px;

    @include tablet-and-above {
      width: auto;
    }

    &--active {
      color: $color-primary;
      font-weight: bold;
      opacity: 1;
    }
  }

  &__justifyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__progressContainer {
    align-items: center;
    margin-bottom: 12px;
    padding: 0 3px 0 11px;

    @include tablet-and-above {
      padding: 0 16px;
    }
  }
}
