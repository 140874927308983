@import '../../styles/all';

.c-DropzoneUploader {
  .filepond--wrapper {
    background-color: $gray-98;
    border: 2px dashed $rich-purple;
    border-radius: 36px;
    color: $rich-purple;
    outline: none;
    transition: border 0.24s ease -in -out;
  }

  .filepond--root {
    margin: 0;
  }

  .filepond--panel-root {
    background: none;
  }

  &__smallLabel {
    font-size: 10px;
    padding: 11px 0;
  }
}
